import React from "react";

function PowerSwitch() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      data-name="Layer 1"
      viewBox="0 0 32 32"
    >
      <path d="M22.364 6.808a2 2 0 000 2.828 9 9 0 11-12.728 0 2 2 0 00-2.828-2.828 13 13 0 1018.384 0 2 2 0 00-2.828 0z"></path>
      <path d="M16 15.8a2.01 2.01 0 002-2V5a2 2 0 00-4 0v8.8a2.01 2.01 0 002 2z"></path>
    </svg>
  );
}

export default PowerSwitch;