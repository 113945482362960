import React, { useState, useEffect, useRef } from 'react'
import './DropdownSpecialObject.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClearIcon from '@mui/icons-material/Clear'

function DropdownSpecialObject({
  state, margin, fontSize, setState, height, width, idList, index, placeholder, listArray,
  searchBar, parentDivRef, maxHeightUL, disabled, valueToShow, valueToShow2, autoSelect
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState("")
  const [searchValue, setSearchValue] = useState("")

  const ref = useRef(null)
  const dropdownListRef = useRef(null)

  useEffect(() => {
    if (listArray.length == 1 & autoSelect) {
      setSelectedValue(listArray[0][valueToShow])
      setState(listArray[0])
    } else {
      setSelectedValue("")
      setState({})
    }
  }, [listArray])

  useEffect(() => {
    if (!state) {
      return
    }

    setSelectedValue(state[valueToShow])

  }, [state])

  const onClickOutside = () => {
    const dropdownList = document.querySelectorAll(`#${idList}`)

    Array.from(dropdownList).forEach(dropdown => {
      dropdown.classList.remove("active")
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    setIsOpen(false)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])

  useEffect(() => {
    let dropdownElement = null;
    let parentDivElement = null;

    if (isOpen) {
      dropdownElement = dropdownListRef.current

      if (parentDivRef == undefined) {
        parentDivElement = dropdownElement.parentNode.parentNode
      } else {
        parentDivElement = parentDivRef.current
      }
    }

    if (dropdownElement) {
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const parentDivRect = parentDivElement.getBoundingClientRect()

      const { top: dropdownTop, height: dropdownHeight } = dropdownRect
      const { top: parentDivTop, height: parentDivHeight } = parentDivRect

      const dropdownBottom = dropdownTop + dropdownHeight
      const parentDivBottom = parentDivTop + parentDivHeight

      if (dropdownBottom > parentDivBottom) {
        // Calculate the amount to scroll by
        const scrollAmount = dropdownBottom - parentDivBottom + 20
        // Scroll the parent div with smooth animation
        parentDivElement.scrollBy({
          top: scrollAmount,
          behavior: 'smooth',
        })
      }
    }
  }, [isOpen])

  const handleClickDropdown = (event) => {
    setIsOpen(true)
    const dropdownList = event.currentTarget.nextSibling
    dropdownList.classList.toggle("active")

    if (searchBar) {
      const searchBar = document.querySelector(`#search-bar--${idList}`)
      searchBar.focus()
    }
  }

  const onclickMenuItem = (event) => {
    const dropdownList = event.currentTarget.parentNode
    const id = event.currentTarget.getAttribute('data-id')

    const selectedObject = listArray.find(obj => obj.id == id)

    setSelectedValue(selectedObject[valueToShow])
    setState(selectedObject)

    dropdownList.classList.remove("active")
    setIsOpen(false)
  }

  const handleClearDropdownField = (e) => {
    e.stopPropagation()
    setSelectedValue("")
    setState(null)
  }

  return (
    <div className={`dropdown__container-dropdown projects flex--vert ${disabled ? "disabled" : ""}`} id={`dropdown__${index}`} style={{ width, margin }} ref={ref} key={index}>
      <span className="input_field__input_box--text--dropdown dropdown projects flex" style={{ height }} onClick={handleClickDropdown}>
        <p className={`dropdown-input--text_field ${selectedValue == undefined | selectedValue == "" ? "placeholder" : selectedValue}`} style={{ fontSize }}>{selectedValue == undefined | selectedValue == "" ? placeholder : selectedValue}</p>
        {
          selectedValue ?
            <ClearIcon onClick={handleClearDropdownField}/> :
            <KeyboardArrowDownIcon />
        }
      </span>
      <ul className="input_field__dropdown projects" style={{ "maxHeight": maxHeightUL }} id={idList} key={index} ref={dropdownListRef}>
        {
          searchBar &&
          <input className="search-bar dropdown" id={`search-bar--${idList}`} placeholder="Search..." value={searchValue} onChange={event => setSearchValue(event.currentTarget.value)} autoComplete="off" />
        }
        {
          listArray
            .filter(item => Object.values(item).some(value => String(value).toLocaleLowerCase().includes(searchValue)))
            .map((item, index) => {
              let key = valueToShow
              if (!item[valueToShow]) {
                key = valueToShow2
              }

              return (
                <li className='dropdown__item' key={index} data-index={index} data-id={item.id} style={{ fontSize }} onClick={onclickMenuItem}>
                  {item[key]}
                </li>
              )
            })
        }

      </ul>
    </div>
  )
}

export default DropdownSpecialObject

DropdownSpecialObject.defaultProps = {
  searchBar: true,
  height: "30px",
  margin: "5px 0",
  maxHeightUL: "auto",
  placeholderSkeleton: false,
  showID: false,
}