import React, { useState, useEffect, useRef } from 'react'
import './CompanyDetailPage.css'
import httpClient from '../components/helpers/httpClient'
import { useNavigate, useParams } from "react-router-dom"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Dropdown from '../components/Dropdown'
import CheckBox from '../components/CheckBox'
import DropdownMultiselectTags from '../components/DropdownMultiselectTags'
import { DateField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { toast } from "react-toastify"
import Note from '../components/Note'
import { PopupWindow, closePopupWindow } from '../components/PopupWindow'
import { formatDate } from '../components/helpers/formatDate'
import { PopOutWindow } from '../components/PopOutWindow'
import { noteTypes } from '../assets/data/data'
import AddContactToCompany from '../components/AddContactToCompany'
import DropdownSpecialObject from '../components/DropdownSpecialObject'
import DropdownMultiselect from '../components/DropdownMultiselect'

function CompanyDetailPage() {
  const [company, setCompany] = useState([])
  const [edit, setEdit] = useState(false)
  const [companyType, setCompanyType] = useState("")
  const [tagsList, setTagsList] = useState([])
  const [tags, setTags] = useState([])
  const [expandedSection, setExpandedSection] = useState(null)
  const [notes, setNotes] = useState([])
  const [selectedNote, setSelectedNote] = useState(null)
  const [noteDate, setNoteDate] = useState(dayjs())
  const [noteType, setNoteType] = useState("Note")
  const [contacts, setContacts] = useState([])
  const [contactsList, setContactsList] = useState([])
  const [linkNewContact, setLinkNewContact] = useState(false)
  const [loadingContactLink, setLoadingContactLink] = useState(false)
  const [projects, setProjects] = useState([])
  const [buildings, setBuildings] = useState([])
  const [properties, setProperties] = useState([])
  const [units, setUnits] = useState([])
  const [noteProject, setNoteProject] = useState({})
  const [noteBuilding, setNoteBuilding] = useState({})
  const [noteProperty, setNoteProperty] = useState({})
  const [noteUnit, setNoteUnit] = useState({})
  const [notePopupIsActive, setNotePopupIsActive] = useState(false)
  const [noteDetailPopupIsActive, setNoteDetailPopupIsActive] = useState(false)
  const [selectedCompanyFunctions, setSelectedCompanyFunctions] = useState([])

  const navigate = useNavigate()
  const { companyID } = useParams()

  const formSectionAddressInfo = useRef(null)
  const formSectionShippingInfo = useRef(null)

  const companyFunctions = useSelector(state => state.companiesPage.companyFunctions)

  const fetchCompany = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/companies/get?companyID=${companyID}`)

    setCompanyType(response.data.company_type)
    setSelectedCompanyFunctions(response.data.company_functions)

    setCompany(response.data)
  }

  const fetchNotes = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/notes/get/${companyID}?type=company`)
    setNotes(response.data)
  }

  const fetchTagsList = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/tags/get")
    setTagsList(response.data)
  }

  const fetchTags = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/tags/get/${companyID}?type=company`)
    setTags(response.data)
  }

  const fetchLinkedContactsList = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/contacts/get`)
    setContactsList(response.data)
  }

  const fetchLinkedContacts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/company-contacts/get/${companyID}?type=contacts`)
    setContacts(response.data)
  }

  const fetchProjects = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/projects/get")
    setProjects(response.data)
  }

  const fetchBuildings = async () => {
    const data = {
      filters: {
        project_id: noteProject?.id,
      }
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/buildings/get", data)
    setBuildings(response.data)
  }

  const fetchUnits = async () => {
    const data = {
      filters: {
        building_id: noteBuilding?.id
      }
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/units/get", data)
    setUnits(response.data)
  }

  useEffect(() => {
    fetchBuildings()
  }, [noteProject])

  useEffect(() => {
    fetchUnits()
  }, [noteBuilding])

  useEffect(() => {
    fetchUnits()
  }, [noteProperty])

  useEffect(() => {
    fetchCompany()
    fetchTagsList()
    fetchTags()
    fetchNotes()
    fetchLinkedContacts()
    fetchLinkedContactsList()
    fetchProjects()

    const companyDetails = document.querySelector("#company-details")
    const inputFields = companyDetails.querySelectorAll(".input_field")
    const checkboxes = companyDetails.querySelectorAll(".checkbox__container")
    const dropdowns = companyDetails.querySelectorAll(".dropdown__container-dropdown")

    const formElements = [inputFields, checkboxes, dropdowns]

    formElements.forEach(elementList => {
      if (elementList.length > 0) {
        elementList.forEach(element => {
          element.classList.add("disabled")
        })
      }
    })
  }, [])

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleToggleSection = (sectionId) => {
    if (expandedSection === sectionId) {
      // If the section is already expanded, collapse it
      setExpandedSection(null)
    } else {
      // Expand the selected section
      setExpandedSection(sectionId)
    }
  }

  const handleEditCompany = async () => {
    if (edit) {
      const editButton = document.querySelector("#companies__btn-edit")
      editButton.disabled = true

      let okToSave = true
      const formData = company

      const emailErrorElement = document.getElementById("input_error_email")
      const email = document.getElementById("company__email")

      formData["company_functions"] = selectedCompanyFunctions
      formData["company_type"] = companyType

      const emailValidation = /\S+@\S+\.\S+/

      if (email.value) {
        if (emailValidation.test(email.value)) {
          email.classList.remove("red-border")
          emailErrorElement.classList.add("hide")
        } else {
          email.classList.add("red-border")
          emailErrorElement.classList.remove("hide")
        }
      }

      if (!okToSave) {
        toast.error("Please complete all fields")
        document.getElementById("companies__btn-edit").disabled = false
        return
      }

      console.log(formData)

      const response = await toast.promise(
        httpClient.put(process.env.REACT_APP_API_URL + `/api/company/update?companyID=${companyID}`, formData),
        {
          pending: "Update company...",
          success: "Company has been update 👍",
          error: "Something went wrong 😢"
        }
      )
      console.log(response.data)

      fetchCompany()

      editButton.disabled = false
      setEdit(!edit)
    } else {
      setEdit(!edit)

      const companyDetails = document.querySelector("#company-details")
      const inputFields = companyDetails.querySelectorAll(".input_field")
      const checkboxes = companyDetails.querySelectorAll(".checkbox__container")
      const dropdowns = companyDetails.querySelectorAll(".dropdown__container-dropdown")

      const formElements = [inputFields, checkboxes, dropdowns]

      formElements.forEach(elementList => {
        if (elementList.length > 0) {
          elementList.forEach(element => {
            element.classList.remove("disabled")
          })
        }
      })
    }
  }

  const setStateTag = async (tagID, type = "add") => {
    if (type == "add") {
      const response = await httpClient.put(process.env.REACT_APP_API_URL + `/api/tags/add/${companyID}?tagID=${tagID}&type=company`)
    }

    if (type == "remove") {
      const response = await httpClient.delete(process.env.REACT_APP_API_URL + `/api/tags/remove/${companyID}?tagID=${tagID}&type=company`)
    }
  }

  const handleCreateNote = () => {
    setNotePopupIsActive(true)
  }

  const handleSaveNewNote = async () => {
    const subject = document.querySelector("#note_subject").value
    const content = document.querySelector("#note_content").value

    const data = {
      subject, content, noteDate, noteType, companyID, noteBuilding, noteProject, noteProperty, noteUnit
    }

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + `/api/notes/add`, data),
      {
        pending: "Creating note...",
        success: "Note has been created 👍",
        error: "Something went wrong 😢"
      }
    )
    console.log(response.data)

    fetchNotes()
    setNotePopupIsActive(false)
  }

  const handleSetSelectedCompanyFunctions = (value, action) => {
    if (action == "add") {
      setSelectedCompanyFunctions(prevArray => [...prevArray, value])
    } else {
      setSelectedCompanyFunctions(prevArray => prevArray.filter(item => item.id !== value.id))
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompany(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className='template'>

      <Header>
        <div className="back_button" onClick={handleGoBack}><KeyboardBackspaceIcon /> Go Back</div>

        <div className='header__options--right'>
          {/* <button className='options-button-header medium' onClick={handleCreateNote}>create note</button> */}
          <button className='primary-button-header' id="companies__btn-edit" onClick={handleEditCompany}>{edit ? "SAVE" : "EDIT DETAILS"}</button>
        </div>
      </Header>

      <MenuBar />

      <div className="template__body" id='body-company-detail'>

        <PopOutWindow popupID={"popout-company-create-note"} isActive={notePopupIsActive} setIsActive={setNotePopupIsActive}>
          <div className='popout_window__header'>
            <button className='primary-button-header' onClick={handleSaveNewNote}>SAVE</button>
          </div>

          <div className='form__section' >
            <div className="input_field">
              <label className="input_field__label" htmlFor="note_date">Note date</label>
              <DateField className="date-picker" format="DD-MM-YYYY" value={noteDate} onChange={(newValue) => setNoteDate(newValue)} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_type">Note type</label>
              <Dropdown
                index={"note-type"}
                listArray={Object.keys(noteTypes)}
                state={noteType}
                setState={setNoteType}
                placeholder={"Select note type"}
                width={"50%"}
                height="40px"
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_type">Project</label>
              <DropdownSpecialObject
                index={"note-projects"}
                listArray={projects}
                valueToShow="project_placeholder_name"
                valueToShow2="project_commercial_name"
                state={noteProject}
                setState={setNoteProject}
                placeholder={"Select project"}
                width={"50%"}
                height="40px"
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_type">Building</label>
              <DropdownSpecialObject
                index={"note-buildings"}
                listArray={buildings}
                valueToShow="building_name"
                autoSelect={true}
                state={noteBuilding}
                setState={setNoteBuilding}
                placeholder={"Select building"}
                width={"50%"}
                height="40px"
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_type">Unit</label>
              <DropdownSpecialObject
                index={"note-unit"}
                listArray={units}
                valueToShow="unit_name"
                autoSelect={true}
                state={noteUnit}
                setState={setNoteUnit}
                placeholder={"Select unit"}
                width={"50%"}
                height="40px"
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_subject">Subject</label>
              <input className="input_field__input_box--text" id="note_subject" type="text" name="note_subject" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_content">Content</label>
              <textarea className="input_field__input_box--text-area" id="note_content" type="text" name="note_content" />
            </div>

          </div>
        </PopOutWindow>

        <div className={`body__section ${expandedSection === "company-details" ? "full-screen" : ""}`} id="company-details">
          <h2 onClick={() => handleToggleSection("company-details")}>DETAILS</h2>
          <div className="form__section">

            <div className="input_field">
              <label className="input_field__label" htmlFor="company_name">Name</label>
              <input 
                className="input_field__input_box--text" 
                id="company_name" 
                type="text" 
                name="company_name" 
                value={company.company_name || ''} 
                onChange={handleInputChange} 
              />
            </div>

            <div className="input_field--combo-5050--grid">
              <div className="input_field">
                <label className="input_field__label" htmlFor="company_number">Company number</label>
                <input 
                  className="input_field__input_box--text" 
                  id="company_number" 
                  type="text" 
                  name="company_number" 
                  value={company.company_number || ''} 
                  onChange={handleInputChange} 
                />
              </div>

              <div className='input_field'>
                <label className="input_field__label" htmlFor="company_type">Company type</label>
                <Dropdown
                  index={"company-type"}
                  listArray={["NV", "BV", "VOF", "CV", "MS", "CommV"]}
                  setState={setCompanyType}
                  state={companyType}
                  placeholder={""}
                  width={"100%"}
                  searchBar={true}
                  margin={"0"}
                  height={"33px"}
                />
              </div>
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="company_functions">Company functions</label>
              <DropdownMultiselect
                index={"company-functions"}
                listArray={companyFunctions}
                state={selectedCompanyFunctions}
                setState={handleSetSelectedCompanyFunctions}
                placeholder={""}
                width={"100%"}
                displayValueKey={"function"}
              />
            </div>

          <div className="input_field">
            <label className="input_field__label" htmlFor="email">Email</label>
            <input 
              className="input_field__input_box--text" 
              id="company__email" 
              type="text" 
              name="email" 
              value={company.email || ''} 
              onChange={handleInputChange} 
            />
          </div>
          <div className="input_error hide" id="input_error_email">I suspect this is not a valid email address 🤖</div>

          <div className="input_field">
            <label className="input_field__label" htmlFor="email">Website</label>
            <input 
              className="input_field__input_box--text" 
              id="company__website" 
              type="text" 
              name="website" 
              value={company.website || ''} 
              onChange={handleInputChange} 
            />
          </div>

          <div className="input_field">
            <label className="input_field__label" htmlFor="phone">Business phone</label>
            <input 
              className="input_field__input_box--text" 
              id="company_phone" 
              type="text" 
              name="phone" 
              value={company.phone || ''} 
              onChange={handleInputChange} 
            />
          </div>

          <div className="input_field">
            <label className="input_field__label" htmlFor="total_amount_of_shares">company shares amount</label>
            <input 
              className="input_field__input_box--text half" 
              id="company_total_shares" 
              type="text" 
              name="total_amount_of_shares" 
              value={company.total_amount_of_shares || ''} 
              onChange={handleInputChange} 
            />
          </div>

        </div>

        <div className="form__section" id="address_info" ref={formSectionAddressInfo}>
          <div className="input_field">
            <label className="input_field__label" htmlFor="address_street">Address</label>
            <input 
              className="input_field__input_box--text" 
              id="company__address" 
              type="text" 
              name="street" 
              value={company.street || ''} 
              onChange={handleInputChange} 
            />
          </div>
          <div className="input_field--combo-3070">
            <div className="input_field">
              <label className="input_field__label" htmlFor="address_pc">Postal code</label>
              <input 
                className="input_field__input_box--text" 
                id="company__address_pc" 
                type="text" 
                name="postal_code" 
                value={company.postal_code || ''} 
                onChange={handleInputChange} 
              />
            </div>
            <div className="input_field">
              <label className="input_field__label" htmlFor="address_place">City</label>
              <input 
                className="input_field__input_box--text" 
                id="company__address_place" 
                type="text" 
                name="city" 
                value={company.city || ''} 
                onChange={handleInputChange} 
              />
            </div>
          </div>

          <div className="input_field">
            <label className="input_field__label" htmlFor="address_province">Province</label>
            <input 
              className="input_field__input_box--text" 
              id="companies__address_place" 
              type="text" 
              name="province" 
              value={company.province || ''} 
              onChange={handleInputChange} 
            />
          </div>

          <div className="input_field">
            <label className="input_field__label" htmlFor="address_nr">Country</label>
            <input 
              className="input_field__input_box--text" 
              id="company__address_country" 
              type="text" 
              name="country" 
              value={company.country || 'Belgium'} 
              onChange={handleInputChange} 
            />
          </div>
        </div>

        <div className="form__section" id="other_info" ref={formSectionShippingInfo}>
          <div className="input_field">
            <label className="input_field__label" htmlFor="address_street">Shipping address</label>
            <input 
              className="input_field__input_box--text" 
              id="company_shipping__address" 
              type="text" 
              name="shipping_street" 
              value={company.shipping_street || ''} 
              onChange={handleInputChange} 
            />
          </div>
          <div className="input_field--combo-3070">
            <div className="input_field">
              <label className="input_field__label" htmlFor="address_pc">Shipping postal code</label>
              <input 
                className="input_field__input_box--text" 
                id="company_shipping__address_pc" 
                type="text" 
                name="shipping_postal_code" 
                value={company.shipping_postal_code || ''} 
                onChange={handleInputChange} 
              />
            </div>
            <div className="input_field">
              <label className="input_field__label" htmlFor="address_place">city</label>
              <input 
                className="input_field__input_box--text" 
                id="company_shipping__address_place" 
                type="text" 
                name="shipping_city" 
                value={company.shipping_city || ''} 
                onChange={handleInputChange} 
              />
            </div>
          </div>

          <div className="input_field">
            <label className="input_field__label" htmlFor="address_province">Shipping province</label>
            <input 
              className="input_field__input_box--text" 
              id="company_shipping__address_place" 
              type="text" 
              name="shipping_province" 
              value={company.shipping_province || ''} 
              onChange={handleInputChange} 
            />
          </div>

          <div className="input_field">
            <label className="input_field__label" htmlFor="address_nr">Country</label>
            <input 
              className="input_field__input_box--text" 
              id="company_shipping__address_country" 
              type="text" 
              name="shipping_country" 
              value={company.shipping_country || 'Belgium'} 
              onChange={handleInputChange} 
            />
          </div>
        </div>

      </div>

      <div className={`body__section ${expandedSection === "company-tags" ? "full-screen" : ""}`} id="company-tags">
        <h2 onClick={() => handleToggleSection("company-tags")}>TAGS</h2>
        <div className="form__section">
          <DropdownMultiselectTags
            state={tags}
            listArray={tagsList}
            setState={setStateTag}
            type="company"
          />
        </div>
      </div>
      <div className={`body__section ${expandedSection === "company-notes" ? "full-screen" : ""}`} id="company-notes">
        <PopupWindow popupID={"popup-company-notes"} isActive={noteDetailPopupIsActive} setIsActive={setNoteDetailPopupIsActive}>
          {
            selectedNote != null &&
            <div className='note__container--popup'>
              <div className='date_note--popup'>
                <div className='popup_window__subtitle'>Date:</div>
                <p>{formatDate(selectedNote.date_note)}</p>
              </div>
              <div className='date_note--popup'>
                <div className='popup_window__subtitle'>Created by:</div>
                <p>{selectedNote.note_maker_user_id?.first_name}</p>
              </div>
              <div className='date_note--popup'>
                <div className='popup_window__subtitle'>Subject:</div>
                <p>{selectedNote.subject}</p>
              </div>
              <div className='note_text--popup'>
                <div className='popup_window__subtitle'>Note:</div>
                <p>{selectedNote.note}</p>
              </div>
            </div>
          }
        </PopupWindow>

        <h2 onClick={() => handleToggleSection("company-notes")}>NOTES</h2>
        <div className="form__section">
          <button className='more--btn' onClick={handleCreateNote}>+</button>

          {
            notes.map((note, index) => {
              return (
                <Note
                  popupWindowID="popup-company-notes"
                  key={index}
                  noteObj={note}
                  type={note.type}
                  note={note.note}
                  subject={note.subject}
                  date={note.date_note}
                  setSelectedNote={setSelectedNote}
                  setPopupActive={setNoteDetailPopupIsActive}
                />
              )
            })
          }
        </div>
      </div>

      <div className={`body__section ${expandedSection === "company-contacts" ? "full-screen" : ""}`} id="company-contacts">

        <h2 onClick={() => handleToggleSection("company-contacts")}>CONTACTS</h2>
        <div className="form__section">
          {
            linkNewContact ?
              loadingContactLink ?
                <div className='flex' style={{ gap: "15px", marginTop: "10px" }}>
                  <div className='skeleton-loader' style={{ height: "30px", width: "100%" }}></div>
                  <div className='skeleton-loader' style={{ height: "30px", width: "50%" }}></div>
                </div> :
                <AddContactToCompany
                  contactsList={contactsList}
                  contactState={""}
                  contactFunctionState={""}
                  setLoading={setLoadingContactLink}
                  companyID={companyID}
                  refreshData={fetchLinkedContacts}
                  setLinkNewContact={setLinkNewContact}
                  maxHeightUL={expandedSection == "contact-companies" ? "350px" : "150px"}
                /> :
              <button className='more--btn' onClick={() => setLinkNewContact(true)}>+</button>
          }
          {
            contacts.map((contact, index) => {
              return (
                <AddContactToCompany
                  key={index}
                  contactsList={contactsList}
                  contactState={`${contact.contact_id.first_name} ${contact.contact_id.last_name}`}
                  contactIDState={contact.contact_id.id}
                  contactFunctionState={contact.function}
                  companyID={companyID}
                  refreshData={fetchLinkedContacts}
                  disabled={true}
                />
              )
            })
          }

        </div>
      </div>


    </div>

    </div >
  )
}

export default CompanyDetailPage