import React, { useState, useEffect } from 'react'
import httpClient from './helpers/httpClient'
import './PopupWindowTransaction.css'
import PopupDropdown from './PopupDropdown'
import { DateField } from '@mui/x-date-pickers'
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from "react-toastify"
import dayjs from 'dayjs'

export function PopupWindowTransaction({ isActive, setIsActive, transactionActionType, selectedTransactionID }) {

  const [loadingProperties, setLoadingProperties] = useState(false)
  const [propertySearchValue, setPropertySearchValue] = useState("")
  const [properties, setProperties] = useState([])
  const [newTransactionObj, setNewTransactionObj] = useState({
    type: "",
    reservationDate: "",
    contractDate: "",
    deedDate: "",
    landPrice: "",
    constructionPrice: "",
    transactionPrice: "0",
    notaryBuyer: "",
    notarySeller: ""
  })
  const [selectedProperties, setSelectedProperties] = useState([])
  const [contacts, setContacts] = useState([])
  const [companies, setCompanies] = useState([])
  const [sellerSearchValue, setSellerSearchValue] = useState("")
  const [buyerSearchValue, setBuyerSearchValue] = useState("")
  const [selectedSellers, setSelectedSellers] = useState([])
  const [selectedBuyers, setSelectedBuyers] = useState([])
  const OWNERSHIP_TYPES = ["volle eigendom", "vrucht gebruik", "naakte eigendom"]
  const OWNERSHIP_TYPE_OPTIONS = [
    "volle eigendom",
    "vrucht gebruik",
    "naakte eigendom"
  ]
  const [menuItem, setMenuItem] = useState("properties")

  const fetchTransaction = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/transactions/get?transactionID=${selectedTransactionID}`)
    console.log(response.data)
    if (response.status == 200) {
      // Transform notary data into simplified format
      const notaryBuyer = response.data.notary_buyer ? {
        id: response.data.notary_buyer.id,
        name: response.data.notary_buyer.company_name
      } : ""
      
      const notarySeller = response.data.notary_seller ? {
        id: response.data.notary_seller.id,
        name: response.data.notary_seller.company_name
      } : ""

      setNewTransactionObj({
        ...newTransactionObj,
        type: response.data.type,
        reservationDate: response.data.reservation_date ? dayjs(response.data.reservation_date) : null,
        contractDate: response.data.contract_date ? dayjs(response.data.contract_date) : null,
        deedDate: response.data.deed_date ? dayjs(response.data.deed_date) : null,
        landPrice: response.data.land_price,
        constructionPrice: response.data.construction_price,
        transactionPrice: response.data.transaction_price,
        notaryBuyer,
        notarySeller
      })

      setSelectedBuyers(response.data.buyers)
      setSelectedSellers(response.data.sellers)
      setSelectedProperties(response.data.properties)
    }
  }

  console.log(newTransactionObj)

  const fetchProperties = async () => {
    setLoadingProperties(true)
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/properties/get?type=including-projects-units`)
    if (response.status == 200) {
      setProperties(response.data)
    }
    setLoadingProperties(false)
  }

  const fetchContacts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/contacts/get`)
    if (response.status == 200) {
      setContacts(response.data)
    }
  }

  const fetchCompanies = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/companies/get?type=limited-info`)
    if (response.status == 200) {
      setCompanies(response.data)
    }
  }

  useEffect(() => {
    const popup = document.getElementById("popup-transaction")
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  useEffect(() => {
    fetchProperties()
    fetchContacts()
    fetchCompanies()
  }, [])

  useEffect(() => {
    setNewTransactionObj({
      ...newTransactionObj,
      sellers: selectedSellers,
      buyers: selectedBuyers,
      properties: selectedProperties
    })
  }, [selectedBuyers, selectedProperties, selectedSellers])

  useEffect(() => {
    if (transactionActionType == "edit") {
      fetchTransaction()
    } else {
      setNewTransactionObj({
        ...newTransactionObj,
        type: "",
        reservationDate: null,
        contractDate: null,
        deedDate: null,
        landPrice: "",
        constructionPrice: "",
        transactionPrice: "0",
        notaryBuyer: "",
        notarySeller: ""
      })
      setSelectedBuyers([])
      setSelectedSellers([])
      setSelectedProperties([])
    }
  }, [selectedTransactionID, transactionActionType])

  const handleSetStateNewTransactionType = (value) => {
    setNewTransactionObj({ ...newTransactionObj, type: value })
  }

  const handleSetStateNewTransactionObj = (field, value) => {
    setNewTransactionObj({ ...newTransactionObj, [field]: value })
  }

  const closePopupWindow = () => {
    setIsActive(false)
  }

  const handleSaveNewTransaction = async () => {
    // Validate required fields
    const requiredFields = {
      type: "Transaction type",
      properties: "Properties",
      sellers: "Sellers",
      buyers: "Buyers",
      notaryBuyer: "Notary Buyer",
      notarySeller: "Notary Seller"
    }

    for (const [field, label] of Object.entries(requiredFields)) {
      if (!newTransactionObj[field] ||
        (Array.isArray(newTransactionObj[field]) && newTransactionObj[field].length === 0)) {
        toast.error(`${label} is required`)
        return
      }
    }

    // Validate ownership percentages
    const calculateTotal = (participants) => {
      return participants.reduce((sum, p) => sum + (Number(p.ownership_percentage) || 0), 0)
    }

    const sellerTotal = calculateTotal(selectedSellers)
    const buyerTotal = calculateTotal(selectedBuyers)

    if (sellerTotal !== 100) {
      toast.error(`Total seller ownership percentage must be 100% (current: ${sellerTotal}%)`)
      return
    }

    if (buyerTotal !== 100) {
      toast.error(`Total buyer ownership percentage must be 100% (current: ${buyerTotal}%)`)
      return
    }

    // If all validations pass, proceed with creating the transaction
    const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/transactions/create`, newTransactionObj), {
      pending: "Creating transaction...",
      error: "Error creating transaction",
      success: "Transaction created successfully"
    })
    if (response.status == 200) {
      closePopupWindow()
    }
  }

  const handleSelectProperty = (property) => {
    // Check if property is already selected to avoid duplicates
    if (selectedProperties.some(p => p.id === property.id)) {
      return
    }
    setSelectedProperties([...selectedProperties, property])
  }

  const handleRemoveProperty = (property) => {
    setSelectedProperties(selectedProperties.filter(p => p.id !== property.id))
  }

  const handleSetStateNewTransactionNotaryBuyer = (value) => {
    setNewTransactionObj({ ...newTransactionObj, notaryBuyer: value })
  }

  const handleSetStateNewTransactionNotarySeller = (value) => {
    setNewTransactionObj({ ...newTransactionObj, notarySeller: value })
  }

  const handleSelectSeller = (contact) => {
    if (selectedSellers.some(s => s.id === contact.id)) {
      return
    }
    setSelectedSellers([...selectedSellers, contact])
  }

  const handleRemoveSeller = (contact) => {
    setSelectedSellers(selectedSellers.filter(s => s.id !== contact.id))
  }

  const handleSelectBuyer = (contact) => {
    if (selectedBuyers.some(b => b.id === contact.id)) {
      return
    }
    setSelectedBuyers([...selectedBuyers, contact])
  }

  const handleRemoveBuyer = (contact) => {
    setSelectedBuyers(selectedBuyers.filter(b => b.id !== contact.id))
  }

  const updateSellerDetails = (contact, field, value) => {
    setSelectedSellers(selectedSellers.map(seller =>
      seller.id === contact.id
        ? { ...seller, [field]: value }
        : seller
    ))
  }

  const updateBuyerDetails = (contact, field, value) => {
    setSelectedBuyers(selectedBuyers.map(buyer =>
      buyer.id === contact.id
        ? { ...buyer, [field]: value }
        : buyer
    ))
  }

  console.log(selectedSellers)

  return (
    <div
      className={`popup__container overlay no-scrollbar ${isActive ? "active" : ""}`}
      id="popup-transaction"
      onClick={closePopupWindow}
    >
      <div
        className='popup__box no-scrollbar'
        onClick={e => e.stopPropagation()}
      >
        <div className='popout_body' id='popout-body-project'>
          <div className='popout_window__header'>
            <div className='popout_window__header--title'>{transactionActionType == "create" ? "Create Transaction" : "Transaction Details"}</div>
            <button className='primary-button-header' onClick={handleSaveNewTransaction}>SAVE</button>
          </div>

          <div className='menu__titles'>
            <h3 className={`${menuItem == "properties" ? "selected" : ""}`} onClick={() => setMenuItem("properties")}>properties</h3>
            <h3 className={`${menuItem == "details" ? "selected" : ""}`} onClick={() => setMenuItem("details")}>details</h3>
          </div>

          <div className={`popout_body__content_container ${menuItem}`}>
            {
              menuItem === "properties" && (
                <div className='panel'>
                  <div className='selected_properties_list'>
                    <h3>Selected properties</h3>
                    <input
                      className='search-bar--list'
                      type="text"
                      name="property_name"
                      value={propertySearchValue}
                      onChange={(e) => setPropertySearchValue(e.currentTarget.value)}
                      placeholder="Search property"
                    />
                    <div className='properties_list'>
                      {
                        selectedProperties.map((property, index) => {
                          // Get unit names from property_x_unit_set
                          const unitNames = property.property_x_unit_set.map(pxu => pxu.unit_id.unit_name).join(', ')
                          // Get project names from property_x_unit_set
                          const projectNames = property.property_x_unit_set
                            .map(pxu => pxu.unit_id.building_id.project_id.project_placeholder_name)
                            .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
                            .join(', ')

                          return (
                            <div className='selected_property_item' key={index} onClick={() => handleRemoveProperty(property)}>
                              <div className='property_item_value'>{property.cadastral_nr}</div>
                              <div className='property_item_value'>{property.location}</div>
                              <div className='property_item_value'>{property.function}</div>
                              <div className='property_item_value'>{unitNames}</div>
                              <div className='property_item_value'>{projectNames}</div>
                              <div className='property_item__icon min'>
                                -
                              </div>
                            </div>
                          )
                        })
                      }
                      {
                        loadingProperties ? (
                          <div className='loading__container'>
                            <CircularProgress size={30} color="inherit" />
                          </div>
                        ) : (
                          properties
                            .filter(property => (
                              !selectedProperties.some(p => p.id === property.id) && (
                                property?.cadastral_nr?.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                                property?.location?.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                                property?.function?.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                                // Search in unit names
                                property?.property_x_unit_set?.some(pxu =>
                                  pxu.unit_id.unit_name.toLowerCase().includes(propertySearchValue.toLowerCase())
                                ) ||
                                // Search in project names
                                property?.property_x_unit_set?.some(pxu =>
                                  pxu.unit_id.building_id.project_id.project_placeholder_name.toLowerCase().includes(propertySearchValue.toLowerCase())
                                )
                              )
                            ))
                            .map((property, index) => {
                              // Get unit names from property_x_unit_set
                              const unitNames = property.property_x_unit_set.map(pxu => pxu.unit_id.unit_name).join(', ')
                              // Get project names from property_x_unit_set
                              const projectNames = property.property_x_unit_set
                                .map(pxu => pxu.unit_id.building_id.project_id.project_placeholder_name)
                                .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
                                .join(', ')

                              return (
                                <div className='property-item' key={index} onClick={() => handleSelectProperty(property)}>
                                  <div className='property_item_value'>{property.cadastral_nr}</div>
                                  <div className='property_item_value'>{property.location}</div>
                                  <div className='property_item_value'>{property.function}</div>
                                  <div className='property_item_value'>{unitNames}</div>
                                  <div className='property_item_value'>{projectNames}</div>
                                  <div className='property_item__icon plus'>
                                    +
                                  </div>
                                </div>
                              )
                            })
                        )
                      }
                    </div>
                  </div>
                </div>
              )}

            {
              menuItem === "details" && (
                <>
                  <div className='panel overflow-y-auto'>
                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="transaction_type">Type</label>
                      <PopupDropdown
                        options={["Oorsprong", "Aankoop", "Verkoop"]}
                        value={newTransactionObj.type}
                        onChange={handleSetStateNewTransactionType}
                        title={"Select type"}
                        searchBar={false}
                      />
                    </div>

                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="reservation_date">Reservation date</label>
                      <DateField className="date-picker" format="DD-MM-YYYY" value={newTransactionObj.reservationDate} onChange={(value) => handleSetStateNewTransactionObj("reservationDate", value)} />
                    </div>
                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="contract_date">Contract date</label>
                      <DateField className="date-picker" format="DD-MM-YYYY" value={newTransactionObj.contractDate} onChange={(value) => handleSetStateNewTransactionObj("contractDate", value)} />
                    </div>
                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="deed_date">Deed date</label>
                      <DateField className="date-picker" format="DD-MM-YYYY" value={newTransactionObj.deedDate} onChange={(value) => handleSetStateNewTransactionObj("deedDate", value)} />
                    </div>

                    <div className="input_field--combo-grid">
                      <div className="price-inputs-container">
                        <div className="sub-price-input">
                          <label className="input_field__label" htmlFor="land_price">Land Price</label>
                          <input
                            className="input_field__input_box--text price"
                            id="land_price"
                            type="number"
                            name="land_price"
                            value={newTransactionObj.landPrice}
                            onChange={(e) => {
                              const landPrice = parseFloat(e.target.value) || 0
                              const constructionPrice = parseFloat(newTransactionObj.constructionPrice) || 0
                              setNewTransactionObj({
                                ...newTransactionObj,
                                landPrice: e.target.value,
                                transactionPrice: (landPrice + constructionPrice).toString()
                              })
                            }}
                          />
                        </div>
                        <div className="sub-price-input">
                          <label className="input_field__label" htmlFor="construction_price">Construction Price</label>
                          <input
                            className="input_field__input_box--text"
                            id="construction_price"
                            type="number"
                            name="construction_price"
                            value={newTransactionObj.constructionPrice}
                            onChange={(e) => {
                              const constructionPrice = parseFloat(e.target.value) || 0
                              const landPrice = parseFloat(newTransactionObj.landPrice) || 0
                              setNewTransactionObj({
                                ...newTransactionObj,
                                constructionPrice: e.target.value,
                                transactionPrice: (landPrice + constructionPrice).toString()
                              })
                            }}
                          />
                        </div>
                        <div className="total-price">
                          <label className="input_field__label" htmlFor="transaction_price">Total Price</label>
                          <input
                            className="input_field__input_box--text"
                            id="transaction_price"
                            type="text"
                            name="transaction_price"
                            value={newTransactionObj.transactionPrice}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="notary_buyer">Notary Buyer</label>
                      <PopupDropdown
                        searchBar={true}
                        options={companies}
                        value={newTransactionObj.notaryBuyer}
                        onChange={(value) => handleSetStateNewTransactionNotaryBuyer(value)}
                        title={"Select notary buyer"}
                      />
                    </div>

                    <div className="input_field--combo-grid">
                      <label className="input_field__label" htmlFor="notary_seller">Notary Seller</label>
                      <PopupDropdown
                        searchBar={true}
                        options={companies}
                        value={newTransactionObj.notarySeller}
                        onChange={(value) => handleSetStateNewTransactionNotarySeller(value)}
                        title={"Select notary seller"}
                      />
                    </div>

                  </div>

                  <div className='panel'>
                    <div className='selected_contacts_list'>
                      <div className='sellers_list'>
                        <h3>Sellers</h3>
                        <input
                          className='search-bar--list'
                          type="text"
                          name="seller_name"
                          value={sellerSearchValue}
                          onChange={(e) => setSellerSearchValue(e.currentTarget.value)}
                          placeholder="Search seller"
                        />
                        <div className='contacts_list'>
                          {selectedSellers.map((contact, index) => (
                            <div className='selected_contact_item' key={index}>
                              <div className='contact_item_value'>{contact.first_name} {contact.last_name}</div>
                              <div className='contact_item_inputs'>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={contact.ownership_percentage || ''}
                                  onChange={(e) => updateSellerDetails(contact, 'ownership_percentage', e.target.value)}
                                  placeholder="%"
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <PopupDropdown
                                  options={OWNERSHIP_TYPE_OPTIONS}
                                  value={contact.ownership_type || ''}
                                  onChange={(value) => updateSellerDetails(contact, 'ownership_type', value)}
                                  title="Select type"
                                  onClick={(e) => e.stopPropagation()}
                                  formatType="input-field"
                                />
                              </div>
                              <div className='property_item__icon min' onClick={() => handleRemoveSeller(contact)}>-</div>
                            </div>
                          ))}
                          {[
                            ...contacts.map(item => ({ ...item, type: 'contact' })),
                            ...companies.map(item => ({ 
                              id: item.id,
                              first_name: item.company_name,
                              last_name: '',
                              email: item.email,
                              type: 'company'
                            }))
                          ]
                            .filter(item => (
                              !selectedSellers.some(s => s.id === item.id) && (
                                `${item.first_name} ${item.last_name}`.toLowerCase().includes(sellerSearchValue.toLowerCase()) ||
                                item.email?.toLowerCase().includes(sellerSearchValue.toLowerCase())
                              )
                            ))
                            .map((item, index) => (
                              <div className='contact-item' key={index} onClick={() => handleSelectSeller(item)}>
                                <div className='contact_item_value'>
                                  {item.type === 'company' ? item.first_name : `${item.first_name} ${item.last_name}`}
                                </div>
                                <div className='contact_item_value'>{item.email}</div>
                                <div className='contact_item_value'>{item.type}</div>
                                <div className='property_item__icon plus'>+</div>
                              </div>
                            ))}
                        </div>
                      </div>

                      <div className='buyers_list'>
                        <h3>Buyers</h3>
                        <input
                          className='search-bar--list'
                          type="text"
                          name="buyer_name"
                          value={buyerSearchValue}
                          onChange={(e) => setBuyerSearchValue(e.currentTarget.value)}
                          placeholder="Search buyer"
                        />
                        <div className='contacts_list'>
                          {selectedBuyers.map((contact, index) => (
                            <div className='selected_contact_item' key={index}>
                              <div className='contact_item_value'>{contact.first_name} {contact.last_name}</div>
                              <div className='contact_item_inputs'>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  value={contact.ownership_percentage || ''}
                                  onChange={(e) => updateBuyerDetails(contact, 'ownership_percentage', e.target.value)}
                                  placeholder="%"
                                  onClick={(e) => e.stopPropagation()}
                                />
                                <PopupDropdown
                                  options={OWNERSHIP_TYPE_OPTIONS}
                                  value={contact.ownership_type || ''}
                                  onChange={(value) => updateBuyerDetails(contact, 'ownership_type', value)}
                                  title="Select type"
                                  onClick={(e) => e.stopPropagation()}
                                  formatType="input-field"
                                />
                              </div>
                              <div className='property_item__icon min' onClick={() => handleRemoveBuyer(contact)}>-</div>
                            </div>
                          ))}
                          {[
                            ...contacts.map(item => ({ ...item, type: 'contact' })),
                            ...companies.map(item => ({ 
                              id: item.id,
                              first_name: item.company_name,
                              last_name: '',
                              email: item.email,
                              type: 'company'
                            }))
                          ]
                            .filter(item => (
                              !selectedBuyers.some(b => b.id === item.id) && (
                                `${item.first_name} ${item.last_name}`.toLowerCase().includes(buyerSearchValue.toLowerCase()) ||
                                item.email?.toLowerCase().includes(buyerSearchValue.toLowerCase())
                              )
                            ))
                            .map((item, index) => (
                              <div className='contact-item' key={index} onClick={() => handleSelectBuyer(item)}>
                                <div className='contact_item_value'>
                                  {item.type === 'company' ? item.first_name : `${item.first_name} ${item.last_name}`}
                                </div>
                                <div className='contact_item_value'>{item.email}</div>
                                <div className='contact_item_value'>{item.type}</div>
                                <div className='property_item__icon plus'>+</div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}