import { createSlice } from "@reduxjs/toolkit";

export const projectsPageSlice = createSlice({
  name: "projectsPageState",
  initialState: {
    filters: {
      page: 1,
      sortFilter: "id",
      sortAscending: true,
      searchWord: "",
      statusFilter: {"id": null, "name": ""},
      tagFilter: {"id": null, "name": ""},
    },
    projectsList: [],
    typesList: [],
  },
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateProjectsList: (state, action) => {
      const projectsList = action.payload;
    
      // Check if projectsList is an array
      if (Array.isArray(projectsList)) {
        state.projectsList = projectsList;
      } else {
        state.projectsList = []; // Set to an empty array if not an array
      }
    },
    resetStateProjectsPage: (state) => {
      state.filters = {
        page: 1,
        sortFilter: "id",
        sortAscending: true,
        searchWord: "",
        statusFilter: {"id": null, "name": ""},
        tagFilter: {"id": null, "name": ""},
      }
      state.projectsList = []
    },
    updateTypesList: (state, action) => {
      state.typesList = action.payload;
    },
  },
})

export const { updateFilters, updateProjectsList, resetStateProjectsPage, updateTypesList } = projectsPageSlice.actions
export default projectsPageSlice.reducer