import React, { useState, useEffect } from 'react'
import httpClient from '../components/helpers/httpClient'
import './LoginPage.css'
import { useNavigate, Link, Navigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from "react-toastify"
import { addUserInfo } from "../store/user"

function LoginPage() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const logo_black = require("../assets/logos/Logo-Vitruvi-transparant_Wit.png")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logInUser = async () => {
    setLoading(true)

    try {
      const data = {email, password}
      const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/login", data)

      dispatch(addUserInfo(response.data))

      if (response.data.type == "TBA") {
        toast.error("You have not been authorized yet. Contact admin.")
      } else {
        navigate("/dashboard")
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error("Email and/or password not correct")
      }
    }

    setLoading(false)
  }

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      logInUser()
    }
  }

  return (
    <div className="body-loginpage">
      <img src={logo_black} alt="stoqup logo black" />
      <form className='login-form'>
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='EMAIL'
          onKeyDown={handleEnter}
        />
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type='password'
          placeholder='PASSWORD'
          onKeyDown={handleEnter}
        />

        <button type='button' className='button-login' onClick={logInUser}><div className={`${loading ? "hide" : ""}`}>LOG IN</div>{loading && <CircularProgress color="inherit" size={"2.3rem"} />}</button>
        <div className='login-links'>
          <Link to='/forgotpassword'>Forgot password</Link>
          <Link to='/signup'>Signup</Link>
        </div>
      </form>
    </div>
  )
}

export default LoginPage