import React, { useState, useEffect} from 'react'
import httpClient from './helpers/httpClient'
import './PopupWindowProjectDetail.css'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from "react-toastify"
import { useSelector } from 'react-redux'
import DropdownMultiselectTags from './DropdownMultiselectTags'
import { PopupWindow } from './PopupWindow'
import { formatDate } from '../components/helpers/formatDate'
import { DateField } from '@mui/x-date-pickers'
import Dropdown from '../components/Dropdown'
import { noteTypes } from '../assets/data/data'
import Note from './Note'
import Slider from './Slider'
import ImageDropperCloud from './ImageDropperCloud'
import CircularProgress from '@mui/material/CircularProgress'
import ContentEditor from './ContentEditor'
import { MdDeleteOutline } from "react-icons/md"
import { isValidDateDayJS } from './helpers/globalFns'
import PopupDropdown from './PopupDropdown'
import DropdownMultiselect from './DropdownMultiselect'

function PopupWindowProjectDetail({
  isActive, setIsActive, projectID, project, fetchProject,
  setProject, contactsList
}) {
  const [loadingDeleteImage, setLoadingDeleteImage] = useState(false)
  const [newNoteObj, setNewNoteObj] = useState({})
  const [selectedImage, setSelectedImage] = useState(null)
  const [menuItem, setMenuItem] = useState("general")
  const [notes, setNotes] = useState([])
  const [selectedNote, setSelectedNote] = useState()
  const [notePopupIsActive, setNotePopupIsActive] = useState(false)
  const [noteDetailPopupIsActive, setNoteDetailPopupIsActive] = useState(false)
  const [tags, setTags] = useState([])
  const [projectImages, setProjectImages] = useState([])

  const projectTypes = useSelector(state => state.projectsPage.typesList)

  const fetchNotes = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/notes/get/${projectID}?type=project`)
    if (response.status == 200) {
      setNotes(response.data)
    }
  }

  const fetchTags = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/tags/get/${projectID}?type=project`)
    setTags(response.data)
  }

  const fetchProjectImages = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/images/${projectID}?type=project`)
    setProjectImages(response.data)
  }

  useEffect(() => {
    const popup = document.getElementById('popup-project-detail')
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })

    fetchNotes()
    fetchTags()
  }, [])

  useEffect(() => {
    if (project?.project_program_set?.length == 0) {
      setProject(prevState => {
        const newPrograms = [...prevState.project_program_set, {
          project_id: prevState.id,
          program_nl: "",
          program_fr: "",
          program_en: "",
        }]

        return {
          ...prevState,
          project_program_set: newPrograms
        }
      })
    }

    fetchProjectImages()
  }, [project])

  const closePopupWindow = () => {
    setIsActive(false)
    setNotePopupIsActive(false)
    setNoteDetailPopupIsActive(false)
  }

  const handleSaveNewObject = async () => {

    if (notePopupIsActive) {

      if (notePopupIsActive) {
        if (!isValidDateDayJS(newNoteObj?.noteDate)) {
          toast.error("Date not valid or is empty")
          return
        }

        if (!newNoteObj.noteType || !newNoteObj.contact || !newNoteObj.subject) {
          toast.error("Type, contact and subject are required")
          return
        }
      }

      const data = { ...newNoteObj }
      data["projectID"] = projectID

      const responseProjectNote = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/notes/add`, data),
        {
          success: `Note has been created!`,
          error: "Something went wrong",
          pending: `Creating note...`
        })

      const prevStateNewNote = { ...newNoteObj }
      Object.keys(prevStateNewNote).forEach(key => {
        prevStateNewNote[key] = ''
      })
      setNewNoteObj(prevStateNewNote)

    } else {
      const responseProject = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/projects/update`, project),
        {
          success: `Project has been updated!`,
          error: "Something went wrong",
          pending: `updating project...`
        })

      setIsActive(false)
    }

    fetchProject()
    fetchNotes()
    setNoteDetailPopupIsActive(false)
    setNotePopupIsActive(false)
  }

  const handleOnChangeInputProject = (e) => {
    const key = e.currentTarget.getAttribute("name")
    const value = e.currentTarget.value

    const prevState = { ...project }
    prevState[key] = value
    setProject(prevState)
  }

  const setStateTag = async (tagID, type = "add") => {

    if (type == "add") {
      const response = await httpClient.put(process.env.REACT_APP_API_URL + `/api/tags/add/${projectID}?tagID=${tagID}&type=project`)
      console.log(response.data)
    }

    if (type == "remove") {
      const response = await httpClient.delete(process.env.REACT_APP_API_URL + `/api/tags/remove/${projectID}?tagID=${tagID}&type=project`)
      console.log(response.data)
    }
  }

  const handleCreateNote = () => {
    setNotePopupIsActive(true)
  }

  const setStateNewNote = (key, value) => {
    const prevState = { ...newNoteObj }
    prevState[key] = value
    setNewNoteObj(prevState)
  }

  const setImageAsMain = async () => {
    const response = await httpClient.put(process.env.REACT_APP_API_URL + `/api/images/${selectedImage}?type=set-as-main`)
    console.log(response.data)

    fetchProjectImages()
      .then(() => setSelectedImage(null))
  }

  const deleteImage = async () => {
    setLoadingDeleteImage(true)

    const response = await httpClient.delete(process.env.REACT_APP_API_URL + `/api/images/${selectedImage}?type=project`)
    console.log(response.data)

    fetchProjectImages()
      .then(() => setSelectedImage(null))
      .then(() => setLoadingDeleteImage(false))
  }

  const handleSetContentSliders = (id, state) => {

    const prevState = { ...project }

    if (id == "featured-slider") {
      prevState.is_featured = state
    } else if (id == "published-slider") {
      prevState.is_published = state
    }

    setProject(prevState)
  }

  const handleCreateProgram = () => {
    setProject(prevState => {
      const newPrograms = [...prevState.project_program_set, {
        project_id: prevState.id,
        program_nl: "",
        program_fr: "",
        program_en: "",
      }]

      return {
        ...prevState,
        project_program_set: newPrograms
      }
    })
  }

  const handleStProgram = (event, index, name) => {
    const value = event.currentTarget.value

    setProject(prevState => {
      const newPrograms = [...prevState.project_program_set]

      // Update the specific program at the given index
      newPrograms[index] = {
        ...newPrograms[index],
        [name]: value
      }

      return {
        ...prevState,
        project_program_set: newPrograms
      }
    })
  }

  const deleteProgram = (index) => {
    setProject(prevState => {
      const newPrograms = prevState.project_program_set.filter((_, i) => i !== index)

      return {
        ...prevState,
        project_program_set: newPrograms
      }
    })
  }

  const handleSetStateNewNoteContact = (value) => {
    const prevState = { ...newNoteObj }
    prevState["contact"] = value
    setNewNoteObj(prevState)
  }
  
  const handleSetStateNewNoteType = (value) => {
    const prevState = { ...newNoteObj }
    prevState["noteType"] = value
    setNewNoteObj(prevState)
  }

  const handleSetStateNewProjectType = (value, type = "add") => {
    const prevState = { ...project }
    if (type == "add") {
      prevState["types"].push(value)
    }
    if (type == "remove") {
      prevState["types"] = prevState["types"].filter(type => type != value)
    }
    setProject(prevState)
  }

  return (
    <div
      className={`popup__container project-detail overlay no-scrollbar ${isActive ? "active" : ""}`}
      id='popup-project-detail'
      onClick={closePopupWindow}
      onKeyDown={e => e.key === "Escape" && closePopupWindow()}
    >
      <div className='popup__box no-scrollbar' onClick={e => e.stopPropagation()}>

        <div className='popout_body' id='popout-body-project'>
          <div className='popout_window__header'>
            <div className='popout_window__header--title'>Project | {project.project_commercial_name ? project.project_commercial_name : project.project_placeholder_name}</div>
            <button className='primary-button-header' onClick={handleSaveNewObject}>
              {
                notePopupIsActive ?
                  "SAVE NOTE" :
                  "SAVE"
              }
            </button>
            {
              selectedImage ?
                <div className='header_buttons'>
                  <button className='action-button-header' onClick={setImageAsMain}>
                    Set as main
                  </button>
                  <button className='action-button-header red' onClick={deleteImage}>
                    {
                      loadingDeleteImage ?
                        <CircularProgress color='inherit' size={"1rem"} /> :
                        <p>Delete</p>
                    }
                  </button>
                </div> : null
            }
          </div>

          <div className='menu__titles'>
            <h3 className={`${menuItem == "general" ? "selected" : ""}`} onClick={() => setMenuItem("general")}>general</h3>
            <h3 className={`${menuItem == "content" ? "selected" : ""}`} onClick={() => setMenuItem("content")}>content</h3>
          </div>
          <div className={`details_container ${menuItem}`}>
            {
              menuItem == "general" &&
              <>
                <div className='body__section' id='project-detail-details'>
                  <h2>Details</h2>
                  <div className='form__section no-border no-padding'>
                    <div className='form__section--content'>

                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="project_placeholder_name">Project placeholder name</label>
                        <input className="input_field__input_box--text" id="project_placeholder_name" type="text" name="project_placeholder_name" value={project.project_placeholder_name} onChange={handleOnChangeInputProject} />
                      </div>
                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="project_commercial_name">Project commercial name</label>
                        <input className="input_field__input_box--text" id="project_commercial_name" type="text" name="project_commercial_name" value={project.project_commercial_name} onChange={handleOnChangeInputProject} />
                      </div>
                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="code_aangifte_van_werken">Code aangifte van werken</label>
                        <input className="input_field__input_box--text" id="code_aangifte_van_werken" type="text" name="code_aangifte_van_werken" value={project.code_aangifte_van_werken} onChange={handleOnChangeInputProject} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='body__section' id='project-detail-notes'>
                  <h2>Notes</h2>
                  <div className='form__section no-border no-padding'>
                    <div className='form__section--content'>

                      <PopupWindow popupID={"popup-project-notes-create"} isActive={notePopupIsActive} setIsActive={setNotePopupIsActive}>
                        <div className='note__container--popup'>

                          <div className='form__section no-border no-padding' >
                            <div className="input_field--combo-grid">
                              <label className="input_field__label" htmlFor="note_date">Note date</label>
                              <DateField className="date-picker" format="DD-MM-YYYY" value={newNoteObj.noteDate} onChange={(value) => setStateNewNote("noteDate", value)} />
                            </div>

                            <div className="input_field--combo-grid">
                              <label className="input_field__label" htmlFor="note_type">Note type</label>
                              <PopupDropdown
                                options={Object.keys(noteTypes)}
                                value={newNoteObj.noteType}
                                onChange={handleSetStateNewNoteType}
                                title={"Select note type"}
                              />
                            </div>

                            <div className="input_field--combo-grid">
                              <label className="input_field__label" htmlFor="note_type">Contact</label>
                              <PopupDropdown
                                options={contactsList}
                                value={newNoteObj.contact}
                                onChange={handleSetStateNewNoteContact}
                                title={"Select contact"}
                                searchBar={true}
                              />
                            </div>

                            <div className="input_field--combo-grid">
                              <label className="input_field__label" htmlFor="note_subject">Subject</label>
                              <input className="input_field__input_box--text" id="note_subject" type="text" name="note_subject" value={newNoteObj.note_subject} onChange={(e) => setStateNewNote("subject", e.currentTarget.value)} />
                            </div>

                            <div className="input_field">
                              <label className="input_field__label" htmlFor="note_content">Content</label>
                              <textarea className="input_field__input_box--text-area" id="note_content" type="text" name="note_content" value={newNoteObj.note_content} onChange={(e) => setStateNewNote("content", e.currentTarget.value)} />
                            </div>

                          </div>
                        </div>
                      </PopupWindow>

                      <PopupWindow popupID={"popup-project-notes-detail"} isActive={noteDetailPopupIsActive} setIsActive={setNoteDetailPopupIsActive}>
                        {
                          selectedNote != null &&
                          <div className='note__container--popup'>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Date:</div>
                              <p>{formatDate(selectedNote.date_note)}</p>
                            </div>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Created by:</div>
                              <p>{selectedNote.note_maker_user_id?.first_name}</p>
                            </div>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Contact:</div>
                              {
                                selectedNote.contact_id ?
                                  <p>{selectedNote.contact_id.first_name} {selectedNote.contact_id.last_name}</p> :
                                  selectedNote.company_id ?
                                    <p>{selectedNote.company_id.company_name} (company)</p> : null
                              }
                            </div>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Subject:</div>
                              <p>{selectedNote.subject}</p>
                            </div>
                            <div className='note_text--popup'>
                              <div className='popup_window__subtitle'>Note:</div>
                              <p>{selectedNote.note}</p>
                            </div>
                          </div>
                        }
                      </PopupWindow>

                      <button className='more--btn' onClick={handleCreateNote}>+</button>
                      {
                        notes.map((note, index) => {
                          return (
                            <Note
                              popupWindowID="popup-contact-notes"
                              key={index}
                              noteObj={note}
                              type={note.type}
                              note={note.note}
                              subject={note.subject}
                              date={note.date_note}
                              setSelectedNote={setSelectedNote}
                              setPopupActive={setNoteDetailPopupIsActive}
                            />
                          )
                        })
                      }

                    </div>
                  </div>
                </div>

                <div className='body__section' id='project-detail-tags'>
                  <h2>Tags</h2>
                  <div className="form__section no-border no-padding">
                    <div className="form__section--content">
                      <DropdownMultiselectTags
                        state={tags}
                        setState={setStateTag}
                        marginTop={0}
                      />
                    </div>
                  </div>
                </div>
              </>
            }

            {
              menuItem == "content" &&
              <div className='body__section' id='project-detail-content'>
                <h2>Website</h2>
                <div className="form__section no-border no-padding">
                  <div className='form__section--content'>
                    <div className='sliders'>
                      <Slider
                        idNr={"featured-slider"}
                        text="Is featured"
                        size={1.5}
                        fontSize={"0.9rem"}
                        state={project.is_featured}
                        defaultState={project.is_featured}
                        onClickFunction={handleSetContentSliders}
                      />
                      <Slider
                        idNr={"published-slider"}
                        text="Is published"
                        size={1.5}
                        fontSize={"0.9rem"}
                        state={project.is_published}
                        defaultState={project.is_published}
                        onClickFunction={handleSetContentSliders}
                      />
                    </div>

                    <div className='details_container'>
                      <h3>details</h3>
                      <div className='details_project'>

                        <div className='clmn1'>
                          <h4>Address</h4>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="address_line1">Address line 1</label>
                            <input className="input_field__input_box--text" id="address_line1" type="text" name="address_line1" value={project.address_line1} onChange={handleOnChangeInputProject} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="address_pc">Postal Code</label>
                            <input className="input_field__input_box--text" id="address_pc" type="text" name="address_pc" value={project.address_pc} onChange={handleOnChangeInputProject} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="address_place">Address Place</label>
                            <input className="input_field__input_box--text" id="address_place" type="text" name="address_place" value={project.address_place} onChange={handleOnChangeInputProject} />
                          </div>
                        </div>

                        <div className='clmn2'>
                          <h4>Specific</h4>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="architect">Architect</label>
                            <input className="input_field__input_box--text" id="architect" type="text" name="architect" value={project.architect} onChange={handleOnChangeInputProject} />
                          </div>
                          <div className="input_field">
                            <label className="input_field__label" htmlFor="website">Website</label>
                            <input className="input_field__input_box--text" id="website" type="text" name="website" value={project.website} onChange={handleOnChangeInputProject} />
                          </div>
                        </div>

                        <div className='clmn2'>
                          <h4>Unique Aspect</h4>
                          <div className="input_field">
                            <input className="input_field__input_box--text" id="unique_element_nl" type="text" name="unique_element_nl" placeholder="Dutch version" value={project.unique_element_nl} onChange={handleOnChangeInputProject} />
                          </div>
                          <div className="input_field">
                            <input className="input_field__input_box--text" id="unique_element_fr" type="text" name="unique_element_fr" placeholder="French version" value={project.unique_element_fr} onChange={handleOnChangeInputProject} />
                          </div>
                          <div className="input_field">
                            <input className="input_field__input_box--text" id="unique_element_en" type="text" name="unique_element_en" placeholder="English version" value={project.unique_element_en} onChange={handleOnChangeInputProject} />
                          </div>
                        </div>

                        <div className='clmn2'>
                          <h4>Status</h4>
                          <div className="input_field">
                            <input className="input_field__input_box--text" id="project_status_nl" type="text" name="project_status_nl" placeholder="Dutch version" value={project.project_status_nl} onChange={handleOnChangeInputProject} />
                          </div>
                          <div className="input_field">
                            <input className="input_field__input_box--text" id="project_status_fr" type="text" name="project_status_fr" placeholder="French version" value={project.project_status_fr} onChange={handleOnChangeInputProject} />
                          </div>
                          <div className="input_field">
                            <input className="input_field__input_box--text" id="project_status_en" type="text" name="project_status_en" placeholder="English version" value={project.project_status_en} onChange={handleOnChangeInputProject} />
                          </div>
                        </div>

                        <div className='clmn2'>
                          <h4>Types</h4>
                          <DropdownMultiselect 
                            listArray={projectTypes}
                            state={project.types}
                            setState={handleSetStateNewProjectType}
                            title={"Select type"}
                          />

                        </div>

                      </div>
                    </div>

                    <div className='details_container'>
                      <h3>program</h3>
                      {
                        project.project_program_set.map((program, index) => (
                          <div className='program'>
                            <div>
                              <div className="input_field">
                                <label className='input_field__label'>nl</label>
                                <input className='input_field__input_box--text' name='program_nl' placeholder='Ex. 9 appartementen' value={program.program_nl} onChange={e => handleStProgram(e, index, "program_nl")} />
                              </div>
                            </div>
                            <div>
                              <div className="input_field">
                                <label className='input_field__label'>fr</label>
                                <input className='input_field__input_box--text' name='program_fr' placeholder='Ex. 9 appartements' value={program.program_fr} onChange={e => handleStProgram(e, index, "program_fr")} />
                              </div>
                            </div>
                            <div>
                              <div className="input_field">
                                <label className='input_field__label'>en</label>
                                <input className='input_field__input_box--text' name='program_en' placeholder='Ex. 9 apartments' value={program.program_en} onChange={e => handleStProgram(e, index, "program_en")} />
                              </div>
                            </div>
                            <div className='delete_icon' onClick={() => deleteProgram(index)}>
                              <MdDeleteOutline />
                            </div>
                          </div>
                        ))
                      }
                      <button className='more--btn' onClick={handleCreateProgram}>+</button>

                    </div>

                    <div className='images_container'>
                      <h3>images</h3>
                      <ImageDropperCloud
                        hint={"Upload 1 or more images"}
                        projectID={projectID}
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                        projectImages={projectImages}
                        setProjectImages={setProjectImages}
                        fetchProjectImages={fetchProjectImages}
                      />
                    </div>

                    <div className='content_container'>
                      <h3>short description</h3>
                      <div className='description_container'>
                        <ContentEditor
                          placeholder='Dutch version'
                          content={project}
                          setContent={setProject}
                          stateType={"object"}
                          stateValueName={"short_description_nl"}
                        />
                        <ContentEditor
                          placeholder='French version'
                          content={project}
                          setContent={setProject}
                          stateType={"object"}
                          stateValueName={"short_description_fr"}
                        />
                        <ContentEditor
                          placeholder='English version'
                          content={project}
                          setContent={setProject}
                          stateType={"object"}
                          stateValueName={"short_description_en"}
                        />
                      </div>
                      <h3>long description</h3>
                      <div className='description_container'>
                        <ContentEditor
                          placeholder='Dutch version'
                          content={project}
                          setContent={setProject}
                          stateType={"object"}
                          stateValueName={"long_description_nl"}
                        />
                        <ContentEditor
                          placeholder='French version'
                          content={project}
                          setContent={setProject}
                          stateType={"object"}
                          stateValueName={"long_description_fr"}
                        />
                        <ContentEditor
                          placeholder='English version'
                          content={project}
                          setContent={setProject}
                          stateType={"object"}
                          stateValueName={"long_description_en"}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            }

          </div>
        </div>

      </div>
    </div>
  )
}

export default PopupWindowProjectDetail