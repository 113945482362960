import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: {
    page: 1,
    sortFilter: "id",
    sortAscending: true,
    searchWord: "",
    brandFilter: "",
    productFilter: "",
  },
  productsList: [],
}

const validateState = (state) => {
  if (!Array.isArray(state.productsList)) {
    state.productsList = [];
  }
  return state;
}

export const productsPageSlice = createSlice({
  name: "productsPageState",
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateProductsList: (state, action) => {
      const productsList = action.payload;
      
      if (Array.isArray(productsList)) {
        state.productsList = productsList;
      } else {
        state.productsList = [];
      }
    },
    resetStateProductsPage: (state) => {
      state.filters = {
        page: 1,
        sortFilter: "id",
        sortAscending: true,
        searchWord: "",
        brandFilter: "",
        productFilter: "",
      };
      state.productsList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase('persist/REHYDRATE', (state, action) => {
      if (action.payload && action.payload.productsPageState) {
        return validateState(action.payload.productsPageState);
      }
      return state;
    })
  }
})

export const { updateFilters, updateProductsList, resetStateProductsPage } = productsPageSlice.actions
export default productsPageSlice.reducer
