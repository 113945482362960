import React, { useState, useEffect } from 'react'
import './Label.css'

function Label({ simple, labelColor, bright, title, fontSize }) {

  return (
    <div className={`label ${simple ? "" : labelColor} ${bright ? "bright" : null}`} >
      <div className={`ball ${labelColor} ${bright ? "bright" : null}`}></div>
      <h4 style={simple ? { fontWeight: "300", fontSize: fontSize } : null}>{title}</h4>
    </div>
  )
}

export default Label

Label.defaultProps = {
  labelColor: "gray",
  title: "no title",
  fontSize: "0.75rem",
  simple: false,
  bright: false,
}