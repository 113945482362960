import { createSlice } from "@reduxjs/toolkit";

export const companiesPageSlice = createSlice({
  name: "companiesPageState",
  initialState: {
    filtersCompanies: {
      page: 1,
      sortFilter: "company_number",
      sortAscending: true,
      searchWord: "",
      cityFilter: "",
    },
    companiesList: [],
    companyFunctions: [],
  },
  reducers: {
    updateFilters: (state, action) => {
      state.filtersCompanies = action.payload;
    },
    updateCompaniesList: (state, action) => {
      const companiesList = action.payload;
    
      // Check if companiesList is an array
      if (Array.isArray(companiesList)) {
        state.companiesList = companiesList;
      } else {
        state.companiesList = []; // Set to an empty array if not an array
      }
    },
    resetStateCompaniesPage: (state) => {
      state.filtersCompanies = {
        page: 1,
        sortFilter: "company_number",
        sortAscending: true,
        searchWord: "",
        cityFilter: "",
      };
      state.companiesList = [];
      state.companyFunctions = [];
    },
    updateCompanyFunctions: (state, action) => {
      state.companyFunctions = action.payload;
    },
  },
})

export const { updateFilters, updateCompaniesList, resetStateCompaniesPage, updateCompanyFunctions } = companiesPageSlice.actions
export default companiesPageSlice.reducer