import React, { useState, useEffect } from 'react'
import "./TransactionsPage.css"
import Header from '../components/Header'
import MenuBar from '../components/MenuBar'
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import httpClient from '../components/helpers/httpClient'
import { PopupWindowTransaction } from '../components/PopupWindowTransaction'
import BuyIcon from '../assets/pictos/svg/BuyIcon'
import SellIcon from '../assets/pictos/svg/SellIcon'
import OriginIcon from '../assets/pictos/svg/OriginIcon'
import { formatDate } from '../components/helpers/formatDate'

function TransactionsPage() {
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [filters, setFilters] = useState({
    page: 1,
    sortType: null,
    sortAscending: true,
  })
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(20)
  const [popupTransactionActive, setPopupTransactionActive] = useState(false)
  const [transactionActionType, setTransactionActionType] = useState("create")
  const [selectedTransactionID, setSelectedTransactionID] = useState(null)

  const fetchTransactions = async () => {
    setLoading(true)

    const data = {
      filters: filters
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/transactions/get?page=${filters.page}&limit=${limit}`, data)
    setTransactions(response.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchTransactions()
  }, [])

  const handleOnClickRow = (transactionID) => {
    setTransactionActionType("edit")
    setSelectedTransactionID(transactionID)
    setPopupTransactionActive(true)
  }

  const handleSetSortFilter = (sortType) => {
    console.log(sortType)
  }

  const handlePagination = (type) => {
    console.log(type)
  }

  return (
    <div className='template'>
      <Header>
      </Header>

      <MenuBar />

      <PopupWindowTransaction
        overflow="hidden"
        isActive={popupTransactionActive}
        setIsActive={setPopupTransactionActive}
        transactionActionType={transactionActionType}
        selectedTransactionID={selectedTransactionID}
      />

      <div className="template__body" id="body-transactions">
        <button className="floating__button--bottom-right" id="add--btn" onClick={() => { setPopupTransactionActive(true); setTransactionActionType("create") }} />
        <div className='body__section big' id='all-companies-table'>
          <div className='table'>
            <div className='table__header-5'>
              <div className='table__header--field' onClick={() => handleSetSortFilter("type")}></div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("reservation_date")}>Reservation date</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("notary_seller")}>Notary Seller</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("notary_buyer")}>Notary Buyer</div>
              <div className='table__header--field' onClick={() => handleSetSortFilter("transaction_price")}>€ Price</div>
            </div>
            <div className='table__rows_container'>

              {
                loading ?
                  <LoadingSpinner /> :

                  transactions.map((transaction, index) => {
                    return (
                      <div
                        className={`table__row-5 ${transaction.type == "Aankoop" ? "buy" : transaction.type == "Verkoop" ? "sell" : "origin"}`}
                        key={index}
                        onClick={() => handleOnClickRow(transaction.id)}
                        data-transaction-id={transaction.id}
                      >
                        <div className='table__row--field'>
                          {transaction.type == "Aankoop" ?
                            <div className="table__row--field--icon buy">
                              <BuyIcon />
                            </div> :
                            transaction.type == "Verkoop" ?
                              <div className="table__row--field--icon sell">
                                <SellIcon />
                              </div> :
                              <div className="table__row--field--icon origin">
                                <OriginIcon />
                              </div>}
                        </div>
                        <div className='table__row--field'>{formatDate(transaction.reservation_date)}</div>
                        <div className='table__row--field'>{transaction.notary_seller.company_name}</div>
                        <div className='table__row--field'>{transaction.notary_buyer.company_name}</div>
                        <div className='table__row--field'>€ {transaction.transaction_price}</div>
                      </div>
                    )
                  })
              }
            </div>
            <div className="pagination">
              <ArrowBackIosIcon className={`pagination__btn ${filters.page == 1 ? "grayed-out" : ""}`} onClick={() => handlePagination("previous")} data-type="previous" id="prevPage" />
              <ArrowForwardIosIcon className={`pagination__btn ${filters.page == totalPages ? "grayed-out" : ""}`} onClick={() => handlePagination("next")} data-type="next" id="nextPage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionsPage