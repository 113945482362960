import { createSlice } from "@reduxjs/toolkit";

export const contactsPageSlice = createSlice({
  name: "contactsPageState",
  initialState: {
    filters: {
      page: 1,
      sortFilter: "id",
      sortAscending: true,
      searchWord: "",
      cityFilter: "",
    },
    contactsList: [],
  },
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateContactsList: (state, action) => {
      const contactsList = action.payload;
    
      // Check if contactsList is an array
      if (Array.isArray(contactsList)) {
        state.contactsList = contactsList;
      } else {
        state.contactsList = []; // Set to an empty array if not an array
      }
    },
    resetStateContactsPage: (state) => {
      state.filters = {
        page: 1,
        sortFilter: "id",
        sortAscending: true,
        searchWord: "",
        cityFilter: "",
      };
      state.contactsList = [];
    },
  },
})

export const { updateFilters, updateContactsList, resetStateContactsPage } = contactsPageSlice.actions
export default contactsPageSlice.reducer