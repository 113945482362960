
const prospectPicto = require(`../pictos/researcher.png`)
const emailPicto = require(`../pictos/email.png`)
const formPicto = require(`../pictos/form.png`)
const phonePicto = require(`../pictos/smartphone.png`)
const visitPicto = require(`../pictos/building.png`)
const meetingPicto = require(`../pictos/meeting.png`)
const notePicto = require(`../pictos/note.png`)

export const noteTypes = {
    "Email": {"picto": emailPicto},
    "Phone call": {"picto": phonePicto},
    "Prospection": {"picto": prospectPicto},
    "Form data": {"picto": formPicto},
    "Visit": {"picto": visitPicto},
    "Meeting": {"picto": meetingPicto},
    "Note": {"picto": notePicto},
}

export const projectStatuses = ["Prospect", "Development", "Construct", "Construct/Commercialisatie", "Commercialisatie", "Delivered"]