import React, { useState, useEffect, useRef } from 'react'
import './ProductDetailPage.css'
import httpClient from '../components/helpers/httpClient'
import { useNavigate, useParams } from "react-router-dom"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Dropdown from '../components/Dropdown'
import { toast } from "react-toastify"
import FileDropper from '../components/FileDropper'

function ProductDetailPage() {
  const [product, setProduct] = useState({})
  const [edit, setEdit] = useState(false)
  const [imageURL, setImageURL] = useState("")
  const [fileURL, setFileURL] = useState("")

  const navigate = useNavigate()
  const { productID } = useParams()

  const fetchProduct = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/products/get?productID=${productID}`)

    const data = response.data

    setImageURL(data.picture_link)
    setFileURL(data.technical_fiche_link)
    setProduct(response.data)
  }

  useEffect(() => {
    fetchProduct()

    const productDetails = document.querySelector("#product-detail")
    const inputFields = productDetails.querySelectorAll(".input_field")
    const checkboxes = productDetails.querySelectorAll(".checkbox__container")
    const dropdowns = productDetails.querySelectorAll(".dropdown__container-dropdown")

    const formElements = [inputFields, checkboxes, dropdowns]

    formElements.forEach(elementList => {
      if (elementList.length > 0) {
        elementList.forEach(element => {
          element.classList.add("disabled")
        })
      }
    })
  }, [])

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleEditProduct = async () => {
    if (edit) {
      const editButton = document.querySelector("#product__btn-edit")
      editButton.disabled = true

      const formData = {}

      const inputElements = document.querySelectorAll('.input_field__input_box--text')

      inputElements.forEach(element => {
        const fieldName = element.name
        formData[fieldName] = element.value
      })

      formData["picture_link"] = imageURL
      formData["technical_fiche_link"] = fileURL

      const response = await toast.promise(
        httpClient.put(process.env.REACT_APP_API_URL + `/api/product/update?productID=${productID}`, formData),
        {
          pending: "Update product...",
          success: "Product has been updated 👍",
          error: "Something went wrong 😢"
        }
      )
      console.log(response.data)

      fetchProduct()

      editButton.disabled = false
      setEdit(!edit)
    } else {
      setEdit(!edit)

      const productDetails = document.querySelector("#product-detail")
      const inputFields = productDetails.querySelectorAll(".input_field")
      const checkboxes = productDetails.querySelectorAll(".checkbox__container")
      const dropdowns = productDetails.querySelectorAll(".dropdown__container-dropdown")

      const formElements = [inputFields, checkboxes, dropdowns]

      formElements.forEach(elementList => {
        if (elementList.length > 0) {
          elementList.forEach(element => {
            element.classList.remove("disabled")
          })
        }
      })
    }
  }

  const handleChangeInput = (e, type) => {
    const newProductObj = { ...product }
    newProductObj[type] = e.currentTarget.value

    setProduct(newProductObj)
  }

  return (
    <div className='template'>

      <Header>
        <div className="back_button" onClick={handleGoBack}><KeyboardBackspaceIcon /> Go Back</div>

        <div className='header__options--right'>
          <button className='primary-button-header' id="product__btn-edit" onClick={handleEditProduct}>{edit ? "SAVE" : "EDIT DETAILS"}</button>
        </div>
      </Header>

      <MenuBar />

      <div className="template__body" id='body-product-detail'>
        <div className="body__section" id="product-detail">
          <div className="form__section" id='product-detail-section'>

            <div className="input_field">
              <label className="input_field__label" htmlFor="email">Brand</label>
              <input className="input_field__input_box--text" id="products__brand" type="text" name="brand" value={product.brand} onChange={(e) => handleChangeInput(e, "brand")} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Product</label>
              <input className="input_field__input_box--text" id="products__product" type="text" name="product" value={product.product} onChange={(e) => handleChangeInput(e, "product")} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">SKU producer</label>
              <input className="input_field__input_box--text" id="products__SKU_producer" type="text" name="product_producer_id" value={product.product_producer_id} onChange={(e) => handleChangeInput(e, "product_producer_id")} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Description</label>
              <textarea className="input_field__input_box--text" id="product__product_description" type="text" name="product_description" value={product.product_description} onChange={(e) => handleChangeInput(e, "product_description")} />
            </div>

          </div>

          <div className="form__section" id="image_upload">
            <div className='file__uploader_section'>
              <h1>Image</h1>
              <FileDropper
                type="images"
                URL={imageURL}
                setURL={setImageURL}
                fileType={"product"}
                hint="Upload only 1 image"
                disabled={!edit}
              />
            </div>
          </div>

          <div className="form__section" id="file_upload">
            <div className='file__uploader_section'>
              <h1>Technical file</h1>
              <FileDropper
                type="documents"
                URL={fileURL}
                setURL={setFileURL}
                fileType={"product-files"}
                hint="Upload only 1 document"
                disabled={!edit}
              />
            </div>

          </div>

        </div>

      </div>

    </div>
  )
}

export default ProductDetailPage