import * as React from "react";

const OriginIcon     = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    width="512"
    height="512"
    viewBox="0 0 62 62"
  >
    <path d="M57.4 3.1C50.8.3 44.2 1.7 37.8 3c-6.1 1.3-12 2.5-17.8.3V3c0-1.7-1.3-3-3-3s-3 1.3-3 3v57H4v2h26v-2H20V29.4c2.4.8 4.7 1.1 7 1.1 3.8 0 7.5-.8 11.2-1.5 6.4-1.3 12.4-2.6 18.4-.1l1.4.6V3.3zM16 60V3c0-.6.4-1 1-1s1 .4 1 1v57zm40-33.4c-6.2-2-12.3-.8-18.2.5-6.1 1.3-12 2.5-17.8.3v-22c6.2 2 12.3.8 18.2-.5 6.1-1.3 12-2.5 17.8-.3z"></path>
  </svg>
);

export default OriginIcon;
