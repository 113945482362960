import React, { useState, useEffect } from 'react'
import './AddContactToCompany.css'
import Dropdown from './Dropdown'
import httpClient from './helpers/httpClient'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

function AddContactToCompany({ contactsList, contactState, contactIDState, contactFunctionState, setLoading, companyID, refreshData, setLinkNewContact, disabled, maxHeightUL }) {
    const [contact, setContact] = useState(contactState)
    const [contactID, setContactID] = useState(contactIDState)
    const [contactFunction, setContactFunction] = useState(contactFunctionState)

    AddContactToCompany.defaultProps = {
        disabled: false,
        maxHeightUL: "150px"
    }

    const setStateDropdownContact = async (selectedValue) => {
        console.log(selectedValue)
        setContact(`${selectedValue.first_name} ${selectedValue.last_name}`)
        setContactID(selectedValue.id)

        if (contactFunction != "") {
            setLoading(true)

            const data = {
                "company_id": companyID,
                "function": contactFunction,
                "contact_id": selectedValue.id
            }
            const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/companies/add-link`, data)
            console.log(response.data)

            refreshData()
            setLinkNewContact(false)
            setLoading(false)
        }
    }

    const handleSaveContactFunction = async () => {

        if (contact != "") {
            setLoading(true)

            const data = {
                "company_id": companyID,
                "function": contactFunction,
                "contact_id": contactID
            }
            const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/companies/add-link`, data)
            console.log(response.data)

            refreshData()
            setLinkNewContact(false)
            setLoading(false)
        }
    }

    const handleDeleteCompanyLink = async () => {
        const data = {
            "company_id": companyID,
            "function": contactFunction,
            "contact_id": contactID
        }
        const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/companies/delete-link`, data)
        console.log(response.data)

        refreshData()
    }

    const handleHoverIn = (event) => {
        if (disabled) {
            const parentElement = event.currentTarget
            const deleteBox = parentElement.querySelector(".delete_box")

            deleteBox.classList.remove("hide")
        }
    }

    const handleHoverOut = (event) => {
        if (disabled) {

            const parentElement = event.currentTarget
            const deleteBox = parentElement.querySelector(".delete_box")

            deleteBox.classList.add("hide")
        }
    }

    return (
        <div className={`add_company_to_contact__container`} onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut}>
            <Dropdown
                listArray={contactsList}
                searchBar={true}
                state={contact}
                setState={setStateDropdownContact}
                margin={"10px 0 0 0"}
                maxHeightUL={maxHeightUL}
                width={"100%"}
                placeholder={"Select company"}
                disabled={disabled}
            />
            <input className={`input_field__input_box--text ${disabled ? "disabled" : ""}`} placeholder='Add function' value={contactFunction} onChange={(event) => setContactFunction(event.currentTarget.value)} onBlur={handleSaveContactFunction} />
            {
                disabled ?
                    <div className='delete_box hide' onClick={handleDeleteCompanyLink}><DeleteOutlineIcon /></div> :
                    null
            }
        </div>
    )
}

export default AddContactToCompany
