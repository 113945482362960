import React, { useState, useEffect, useRef } from 'react'
import './ContactDetailPage.css'
import httpClient from '../components/helpers/httpClient'
import { useNavigate, useParams } from "react-router-dom"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import LoadingSpinner from "../components/placeholders/LoadingSpinner"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Dropdown from '../components/Dropdown'
import CheckBox from '../components/CheckBox'
import DropdownMultiselectTags from '../components/DropdownMultiselectTags'
import { DateField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { toast } from "react-toastify"
import AddCompanyToContact from '../components/AddCompanyToContact'
import Note from '../components/Note'
import { PopupWindow, closePopupWindow } from '../components/PopupWindow'
import { formatDate } from '../components/helpers/formatDate'
import { PopOutWindow } from '../components/PopOutWindow'
import { noteTypes } from '../assets/data/data'
import DropdownSpecialObject from '../components/DropdownSpecialObject'

function ContactDetailPage() {
  const [contact, setContact] = useState([])
  const [contactTitle, setContactTitle] = useState("")
  const [isMarried, setIsMarried] = useState(false)
  const [partner, setPartner] = useState({ "id": null, "first_name": "", "last_name": "" })
  const [contactsList, setContactsList] = useState([{ "id": null, "first_name": null, "last_name": null }])
  const [birthday, setBirthday] = useState("")
  const [language, setLanguage] = useState("Dutch")
  const [edit, setEdit] = useState(false)
  const [tagsList, setTagsList] = useState([])
  const [tags, setTags] = useState([])
  const [companiesList, setCompaniesList] = useState([])
  const [companies, setCompanies] = useState([])
  const [linkNewCompany, setLinkNewCompany] = useState(false)
  const [loadingCompanyLink, setLoadingCompanyLink] = useState(false)
  const [expandedSection, setExpandedSection] = useState(null)
  const [notePopupIsActive, setNotePopupIsActive] = useState(false)
  const [noteDetailPopupIsActive, setNoteDetailPopupIsActive] = useState(false)
  const [notes, setNotes] = useState([])
  const [selectedNote, setSelectedNote] = useState(null)
  const [noteDate, setNoteDate] = useState(dayjs())
  const [noteType, setNoteType] = useState("Note")
  const [projects, setProjects] = useState([])
  const [buildings, setBuildings] = useState([])
  const [properties, setProperties] = useState([])
  const [units, setUnits] = useState([])
  const [noteProject, setNoteProject] = useState({})
  const [noteBuilding, setNoteBuilding] = useState({})
  const [noteProperty, setNoteProperty] = useState({})
  const [noteUnit, setNoteUnit] = useState({})

  const navigate = useNavigate()
  const { contactID } = useParams()

  const formSectionAddressInfo = useRef(null)
  const formSectionOtherInfo = useRef(null)

  const fetchContact = async () => {
    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/contacts/get?contactID=${contactID}`)

    response.data.language != null && setLanguage(response.data.language)
    response.data.birthday != null && setBirthday(dayjs(response.data.birthday))
    response.data.title != null && setContactTitle(response.data.title)
    response.data.partner != null && setPartner(response.data.partner)
    response.data.is_married != null && setIsMarried(response.data.is_married)

    setContact(response.data)
  }

  const fetchFullContacts = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/contacts/get")
    setContactsList(response.data)
  }

  const fetchNotes = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/notes/get/${contactID}?type=contact`)
    setNotes(response.data)
  }

  const fetchTagsList = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/tags/get")
    setTagsList(response.data)
  }

  const fetchTags = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/tags/get/${contactID}?type=contact`)
    setTags(response.data)
  }

  const fetchLinkedCompaniesList = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/companies/get`)

    const data = response.data.map(company => {
      return ({
        "id": company.id,
        "company_name": company.company_name,
        "city": company.city
      })
    })

    setCompaniesList(data)
  }

  const fetchLinkedCompanies = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/company-contacts/get/${contactID}?type=companies`)
    setCompanies(response.data)
  }

  const fetchProjects = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/projects/get")
    setProjects(response.data)
  }

  const fetchBuildings = async () => {
    const data = {
      filters: {
        project_id: noteProject?.id,
      }
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/buildings/get", data)
    setBuildings(response.data)
  }

  const fetchUnits = async () => {
    const data = {
      filters: {
        building_id: noteBuilding?.id
      }
    }
    const response = await httpClient.post(process.env.REACT_APP_API_URL + "/api/units/get", data)
    setUnits(response.data)
  }

  useEffect(() => {
    fetchBuildings()
  }, [noteProject])

  useEffect(() => {
    console.log("hello")
    fetchUnits()
  }, [noteBuilding])

  useEffect(() => {
    fetchUnits()
  }, [noteProperty])

  useEffect(() => {
    fetchContact()
    fetchFullContacts()
    fetchTagsList()
    fetchTags()
    fetchLinkedCompaniesList()
    fetchLinkedCompanies()
    fetchNotes()
    fetchProjects()

    const contactDetails = document.querySelector("#contact-details")
    const inputFields = contactDetails.querySelectorAll(".input_field")
    const checkboxes = contactDetails.querySelectorAll(".checkbox__container")
    const dropdowns = contactDetails.querySelectorAll(".dropdown__container-dropdown")

    const formElements = [inputFields, checkboxes, dropdowns]

    formElements.forEach(elementList => {
      if (elementList.length > 0) {
        elementList.forEach(element => {
          element.classList.add("disabled")
        })
      }
    })
  }, [])

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleToggleSection = (sectionId) => {
    if (expandedSection === sectionId) {
      // If the section is already expanded, collapse it
      setExpandedSection(null)
    } else {
      // Expand the selected section
      setExpandedSection(sectionId)
    }
  }

  const handleEditContact = async () => {
    if (edit) {
      const editButton = document.querySelector("#contacts__btn-edit")
      editButton.disabled = true

      let okToSave = true
      const formData = {}

      const emailErrorElement = document.getElementById("input_error_email")
      const email = document.getElementById("customers__email")

      const emailValidation = /\S+@\S+\.\S+/

      const inputElements = document.querySelectorAll('.input_field__input_box--text')

      inputElements.forEach(element => {
        const fieldName = element.name
        formData[fieldName] = element.value
      })

      formData["language"] = language
      formData["is_married"] = isMarried
      formData["partner_id"] = partner.id
      formData["birthday"] = birthday
      formData["title"] = contactTitle

      // let arrayRequiredTextBoxes = []

      // arrayRequiredTextBoxes.forEach((inputElement) => {
      //   if (!inputElement.value) {
      //     inputElement.classList.add("red-border")
      //   }
      // })

      // if (!arrayRequiredTextBoxes.every(item => item.value)) {
      //   okToSave = true
      // }

      if (email.value) {
        if (emailValidation.test(email.value)) {
          email.classList.remove("red-border")
          emailErrorElement.classList.add("hide")
        } else {
          email.classList.add("red-border")
          emailErrorElement.classList.remove("hide")
          okToSave = false
        }
      }

      if (!okToSave) {
        toast.error("Please complete all fields")
        document.getElementById("contacts__btn-save").disabled = false
        return
      }

      const response = await toast.promise(
        httpClient.put(process.env.REACT_APP_API_URL + `/api/contact/update?contactID=${contactID}`, formData),
        {
          pending: "Update contact...",
          success: "Contact has been update 👍",
          error: "Something went wrong 😢"
        }
      )
      console.log(response.data)

      fetchContact()

      editButton.disabled = false
      setEdit(!edit)
    } else {
      setEdit(!edit)

      const contactDetails = document.querySelector("#contact-details")
      const inputFields = contactDetails.querySelectorAll(".input_field")
      const checkboxes = contactDetails.querySelectorAll(".checkbox__container")
      const dropdowns = contactDetails.querySelectorAll(".dropdown__container-dropdown")

      const formElements = [inputFields, checkboxes, dropdowns]

      formElements.forEach(elementList => {
        if (elementList.length > 0) {
          elementList.forEach(element => {
            element.classList.remove("disabled")
          })
        }
      })
    }
  }

  const setStateTag = async (tagID, type = "add") => {
    if (type == "add") {
      const response = await httpClient.put(process.env.REACT_APP_API_URL + `/api/tags/add/${contactID}?tagID=${tagID}&type=contact`)
    }

    if (type == "remove") {
      const response = await httpClient.delete(process.env.REACT_APP_API_URL + `/api/tags/remove/${contactID}?tagID=${tagID}&type=contact`)
    }
  }

  const handleCreateNote = () => {
    setNotePopupIsActive(true)
  }

  const handleSaveNewNote = async () => {
    const subject = document.querySelector("#note_subject").value
    const content = document.querySelector("#note_content").value

    const data = {
      subject, content, noteDate, noteType, contactID, noteProject, noteBuilding, noteProperty, noteUnit
    }

    const response = await toast.promise(
      httpClient.post(process.env.REACT_APP_API_URL + `/api/notes/add`, data),
      {
        pending: "Creating note...",
        success: "Note has been created 👍",
        error: "Something went wrong 😢"
      }
    )
    console.log(response.data)

    fetchNotes()
    setNotePopupIsActive(false)
  }

  return (
    <div className='template'>

      <Header>
        <div className="back_button" onClick={handleGoBack}><KeyboardBackspaceIcon /> Go Back</div>

        <div className='header__options--right'>
          <button className='primary-button-header' id="contacts__btn-edit" onClick={handleEditContact}>{edit ? "SAVE" : "EDIT DETAILS"}</button>
        </div>
      </Header>

      <MenuBar />

      <div className="template__body" id='body-contact-detail'>

        <PopOutWindow popupID={"popout-contact-create-note"} isActive={notePopupIsActive} setIsActive={setNotePopupIsActive}>
          <div className='popout_window__header'>
            <button className='primary-button-header' onClick={handleSaveNewNote}>SAVE</button>
          </div>

          <div className='form__section' >
            <div className="input_field">
              <label className="input_field__label" htmlFor="note_date">Note date</label>
              <DateField className="date-picker" format="DD-MM-YYYY" value={noteDate} name='date' onChange={(newValue) => setNoteDate(newValue)} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_type">Note type</label>
              <Dropdown
                index={"note-type"}
                listArray={Object.keys(noteTypes)}
                state={noteType}
                setState={setNoteType}
                placeholder={"Select note type"}
                width={"50%"}
                height="40px"
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_type">Project</label>
              <DropdownSpecialObject
                index={"note-projects"}
                listArray={projects}
                valueToShow="project_placeholder_name"
                valueToShow2="project_commercial_name"
                state={noteProject}
                setState={setNoteProject}
                placeholder={"Select project"}
                width={"50%"}
                height="40px"
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_type">Building</label>
              <DropdownSpecialObject
                index={"note-buildings"}
                listArray={buildings}
                valueToShow="building_name"
                autoSelect={true}
                state={noteBuilding}
                setState={setNoteBuilding}
                placeholder={"Select building"}
                width={"50%"}
                height="40px"
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_type">Unit</label>
              <DropdownSpecialObject
                index={"note-unit"}
                listArray={units}
                valueToShow="unit_name"
                autoSelect={true}
                state={noteUnit}
                setState={setNoteUnit}
                placeholder={"Select unit"}
                width={"50%"}
                height="40px"
              />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_subject">Subject</label>
              <input className="input_field__input_box--text" id="note_subject" type="text" name="note_subject" />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="note_content">Content</label>
              <textarea className="input_field__input_box--text-area" id="note_content" type="text" name="note_content" />
            </div>

          </div>
        </PopOutWindow>

        <div className={`body__section ${expandedSection === "contact-details" ? "full-screen" : ""}`} id="contact-details">
          <h2 onClick={() => handleToggleSection("contact-details")}>DETAILS</h2>
          <div className="form__section">

            <Dropdown
              index={"contact-title"}
              listArray={["Heer", "Mevrouw", "Juffrouw", "Professor", "Dokter", "Meester"]}
              state={contactTitle}
              setState={setContactTitle}
              placeholder={"Title"}
              width={"50%"}
            />

            <div className="input_field--combo-5050">
              <div className="input_field">
                <label className="input_field__label" htmlFor="first_name">First name</label>
                <input className="input_field__input_box--text" id="customers__first_name" type="text" name="first_name" defaultValue={contact.first_name} />
              </div>
              <div className="input_field">
                <label className="input_field__label" htmlFor="last_name">Last name</label>
                <input className="input_field__input_box--text" id="customers__last_name" type="text" name="last_name" defaultValue={contact.last_name} />
              </div>
            </div>

            <CheckBox
              text="Is married?"
              state={isMarried}
              setState={setIsMarried}
              width={"50%"}
              padding={"0px 2.5px"}
              margin={"7.5px 0"}
            />

            {
              isMarried &&
              <>
                <Dropdown
                  index={"contact-partner"}
                  listArray={contactsList}
                  setState={setPartner}
                  state={`${partner.first_name} ${partner.last_name}`}
                  placeholder={"Connect to partner"}
                  width={"100%"}
                  searchBar={true}
                />
              </>
            }

            <div className="input_field">
              <label className="input_field__label" htmlFor="email">Email</label>
              <input className="input_field__input_box--text" id="customers__email" type="text" name="email" defaultValue={contact.email} />
            </div>
            <div className="input_error hide" id="input_error_email">I suspect this is not a valid email address 🤖</div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Business phone</label>
              <input className="input_field__input_box--text" id="customers__business_phone" type="text" name="business_phone" defaultValue={contact.business_phone} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Home phone</label>
              <input className="input_field__input_box--text" id="customers__home_phone" type="text" name="home_phone" defaultValue={contact.home_phone} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="tel">Mobile phone</label>
              <input className="input_field__input_box--text" id="customers__mobile_phone" type="text" name="mobile_phone" defaultValue={contact.mobile_phone} />
            </div>

          </div>

          <div className="form__section" id="address_info" ref={formSectionAddressInfo}>
            <div className="input_field">
              <label className="input_field__label" htmlFor="address_street">Address</label>
              <input className="input_field__input_box--text" id="customers__address" type="text" name="address" defaultValue={contact.address} />
            </div>
            <div className="input_field--combo-3070">
              <div className="input_field">
                <label className="input_field__label" htmlFor="address_pc">Postal code</label>
                <input className="input_field__input_box--text" id="customers__address_pc" type="text" name="postal" defaultValue={contact.postal} />
              </div>
              <div className="input_field">
                <label className="input_field__label" htmlFor="address_place">City</label>
                <input className="input_field__input_box--text" id="customers__address_place" type="text" name="city" defaultValue={contact.city} />
              </div>
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="address_province">Province</label>
              <input className="input_field__input_box--text" id="customers__address_place" type="text" name="province" defaultValue={contact.province} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="address_nr">Country</label>
              <input className="input_field__input_box--text" id="customers__address_country" type="text" name="country" defaultValue={contact.country} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="birthday">Birthday</label>
              <DateField className="date-picker" format="DD-MM-YYYY" value={birthday} onChange={(newValue) => setBirthday(newValue)} />
            </div>

            <div className="input_field">
              <label className="input_field__label" htmlFor="language">Language</label>
              <Dropdown
                index={"contact-language"}
                listArray={["Dutch", "English", "French", "Spanish", "Other"]}
                setState={setLanguage}
                state={language}
                width={"100%"}
                margin={"0"}
                parentDivRef={formSectionAddressInfo}
              />
            </div>
          </div>

        </div>

        <div className={`body__section ${expandedSection === "contact-notes" ? "full-screen" : ""}`} id="contact-notes">
          <PopupWindow popupID={"popup-contact-notes"} isActive={noteDetailPopupIsActive} setIsActive={setNoteDetailPopupIsActive}>
            {
              selectedNote != null &&
              <div className='note__container--popup'>
                <div className='date_note--popup'>
                  <div className='popup_window__subtitle'>Date:</div>
                  <p>{formatDate(selectedNote.date_note)}</p>
                </div>
                <div className='date_note--popup'>
                  <div className='popup_window__subtitle'>Created by:</div>
                  <p>{selectedNote.note_maker_user_id?.first_name}</p>
                </div>
                <div className='date_note--popup'>
                  <div className='popup_window__subtitle'>Subject:</div>
                  <p>{selectedNote.subject}</p>
                </div>
                <div className='note_text--popup'>
                  <div className='popup_window__subtitle'>Note:</div>
                  <p>{selectedNote.note}</p>
                </div>
              </div>
            }
          </PopupWindow>

          <h2 onClick={() => handleToggleSection("contact-notes")}>NOTES</h2>
          <div className="form__section">
            <button className='more--btn' onClick={handleCreateNote}>+</button>
            {
              notes.map((note, index) => {
                return (
                  <Note
                    popupWindowID="popup-contact-notes"
                    key={index}
                    noteObj={note}
                    type={note.type}
                    note={note.note}
                    subject={note.subject}
                    date={note.date_note}
                    setSelectedNote={setSelectedNote}
                    setPopupActive={setNoteDetailPopupIsActive}
                  />
                )
              })
            }
          </div>
        </div>

        <div className={`body__section ${expandedSection === "contact-tags" ? "full-screen" : ""}`} id="contact-tags">
          <h2 onClick={() => handleToggleSection("contact-tags")}>TAGS</h2>
          <div className="form__section">
            <DropdownMultiselectTags
              state={tags}
              listArray={tagsList}
              setState={setStateTag}
            />
          </div>
        </div>
        <div className={`body__section ${expandedSection === "contact-companies" ? "full-screen" : ""}`} id="contact-companies">
          <h2 onClick={() => handleToggleSection("contact-companies")}>COMPANIES</h2>
          <div className="form__section">
            {
              linkNewCompany ?
                loadingCompanyLink ?
                  <div className='flex' style={{ gap: "15px", marginTop: "10px" }}>
                    <div className='skeleton-loader' style={{ height: "30px", width: "100%" }}></div>
                    <div className='skeleton-loader' style={{ height: "30px", width: "50%" }}></div>
                  </div> :
                  <AddCompanyToContact
                    companiesList={companiesList}
                    companyState={""}
                    companyFunctionState={""}
                    setLoading={setLoadingCompanyLink}
                    contactID={contactID}
                    refreshData={fetchLinkedCompanies}
                    setLinkNewCompany={setLinkNewCompany}
                    maxHeightUL={expandedSection == "contact-companies" ? "350px" : "150px"}
                  /> :
                <button className='more--btn' onClick={() => setLinkNewCompany(true)}>+</button>
            }
            {
              companies.map((company, index) => {
                return (
                  <AddCompanyToContact
                    key={index}
                    companiesList={companiesList}
                    companyState={company.company_id.company_name}
                    companyIDState={company.company_id.id}
                    companyFunctionState={company.function}
                    contactID={contactID}
                    refreshData={fetchLinkedCompanies}
                    disabled={true}
                  />
                )
              })
            }
          </div>
        </div>
      </div>

    </div>
  )
}

export default ContactDetailPage