import React, { useState, useEffect } from 'react'
import './PopupWindowUnitDetail.css'
import httpClient from './helpers/httpClient'
import CloseIcon from '@mui/icons-material/Close'
import { toast } from "react-toastify"
import { PopupWindow } from './PopupWindow'
import { DateField } from '@mui/x-date-pickers'
import Dropdown from '../components/Dropdown'
import { noteTypes } from '../assets/data/data'
import { formatDate } from '../components/helpers/formatDate'
import Note from '../components/Note'
import { isValidDateDayJS } from './helpers/globalFns'
import Sold from '../assets/pictos/svg/Sold'
import PopupDropdown from './PopupDropdown'
import CircularProgress from '@mui/material/CircularProgress'
import { CiSquarePlus } from "react-icons/ci"
import { FaRegEdit } from "react-icons/fa"
import { GoUnlink } from "react-icons/go"
import { HiMiniPower } from "react-icons/hi2"

function PopupWindowUnitDetail({ isActive, setIsActive, actionType, projectID, selectedItem, setSelectedItem, project, selectedItemID, fetchProject, contactsList }) {
  const initialPropertyState = {
    cadastral_nr: '',
    location: '',
    function: '',
    surface: '',
    GFAa_underground: '',
    GFAa: '',
    GFAb: '',
    GFAc: '',
    EPC_label: '',
    description: ''
  }

  const [selectedNote, setSelectedNote] = useState()
  const [loading, setLoading] = useState({
    properties: false
  })
  const [notes, setNotes] = useState([])
  const [notePopupIsActive, setNotePopupIsActive] = useState(false)
  const [noteDetailPopupIsActive, setNoteDetailPopupIsActive] = useState(false)
  const [createPropertyPopupIsActive, setCreatePropertyPopupIsActive] = useState(false)
  const [linkPropertyIsActive, setLinkPropertyIsActive] = useState(false)
  const [newNoteObj, setNewNoteObj] = useState({})
  const [unit, setUnit] = useState(null)
  const [menuItem, setMenuItem] = useState("general")
  const [properties, setProperties] = useState([])
  const [propertiesOfUnit, setPropertiesOfUnit] = useState([])
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [propertySearchValue, setPropertySearchValue] = useState("")
  const [newPropertyObj, setNewPropertyObj] = useState(initialPropertyState)
  const [editProperty, setEditProperty] = useState(false)

  const fetchNotes = async () => {
    if (!unit) {
      return
    }

    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/notes/get/${unit.id}?type=unit`)
    if (response.status == 200) {
      setNotes(response.data)
    }
  }

  const getUnitById = (project, unitId) => {
    if (!project.buildings) {
      return null
    }

    for (const building of project.buildings) {
      const unit = building.units.find(unit => unit.id == unitId)
      if (unit) {
        return unit
      }
    }
    return null
  }

  const fetchProperties = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/properties/get`)
    if (response.status == 200) {
      setProperties(response.data)
    }
  }

  const fetchPropertiesOfUnit = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/properties/properties-of-unit?unitID=${unit.id}`)
    if (response.status == 200) {
      setPropertiesOfUnit(response.data)
    }
  }

  const fetchPropertyFns = () => {
    setLoading({
      ...loading,
      properties: true
    })
    Promise.all([
      fetchProperties(),
      fetchPropertiesOfUnit()
    ])
      .then(() => {
        setLoading({
          ...loading,
          properties: false
        })
      })
  }

  useEffect(() => {
    const popup = document.getElementById('popup-unit-detail')
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        if (popup.classList.contains("active")) {
          closePopupWindow()
        }
      }
    })
  }, [])

  useEffect(() => {
    if (project) {
      setUnit(getUnitById(project, selectedItemID))
    }
  }, [project, isActive])

  useEffect(() => {
    if (isActive) {
      fetchNotes()
    }
  }, [isActive, unit])

  useEffect(() => {
    if (menuItem == "property") {
      fetchPropertyFns()
    }
  }, [menuItem])

  const closePopupWindow = () => {
    setIsActive(false)
    setNotePopupIsActive(false)
    setNoteDetailPopupIsActive(false)
  }

  const handleSaveNewObject = async () => {
    if (menuItem == "general") {
      if (notePopupIsActive) {
        if (!isValidDateDayJS(newNoteObj?.noteDate)) {
          toast.error("Date not valid or is empty")
          return
        }

        if (!newNoteObj.noteType || !newNoteObj.contact || !newNoteObj.subject) {
          toast.error("Type, contact and subject are required")
          return
        }

        const data = { ...newNoteObj }
        data["unitID"] = unit.id

        const responseProjectNote = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/notes/add`, data),
          {
            success: `Note has been created!`,
            error: "Something went wrong",
            pending: `Creating note...`
          })

        // cleanup state
        const prevStateNewNote = { ...newNoteObj }
        Object.keys(prevStateNewNote).forEach(key => {
          prevStateNewNote[key] = ''
        })
        setNewNoteObj(prevStateNewNote)

        setNoteDetailPopupIsActive(false)
        setNotePopupIsActive(false)
        fetchNotes()
      } else {

        if (!unit.unit_name) {
          toast.error("Name is obligatory")
          return
        }
        const responseUnit = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/units/create?itemID=${selectedItemID}&actionType=${actionType}`, unit),
          {
            success: `Unit has been ${actionType == "add" ? "created" : "updated"}!`,
            error: "Something went wrong",
            pending: `${actionType == "add" ? "creating" : "updating"} unit...`
          })

        // cleanup state
        const prevStateSelectedItem = { ...unit }
        Object.keys(prevStateSelectedItem).forEach(key => {
          prevStateSelectedItem[key] = ''
        })

        fetchNotes()
        setIsActive(false)
        setUnit(prevStateSelectedItem)
      }
    }

    if (menuItem == "property") { 
      if (createPropertyPopupIsActive) {
        let data = { ...newPropertyObj }
        data["unitID"] = unit.id

        const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/properties/create`, data),
          {
            success: `Property has been created!`,
            error: "Something went wrong",
            pending: `Creating property...`
          })

        setCreatePropertyPopupIsActive(false)
        fetchPropertyFns()
        setNewPropertyObj(initialPropertyState)
        console.log(response.data)
      } else {
        console.log("linkPropertyIsActive")
      }
    }
  }

  const handleOnChangeInput = (e) => {
    const key = e.currentTarget.getAttribute("name")
    const value = e.currentTarget.value

    const prevState = { ...unit }
    prevState[key] = value

    setUnit(prevState)
  }

  const setStateNewNote = (key, value) => {
    const prevState = { ...newNoteObj }
    prevState[key] = value
    setNewNoteObj(prevState)
  }

  const handleCreateNote = () => {
    setNotePopupIsActive(true)
  }

  const handleSetStateNewNoteType = (value) => {
    const prevState = { ...newNoteObj }
    prevState["noteType"] = value
    setNewNoteObj(prevState)
  }

  const handleSetStateNewNoteContact = (value) => {
    const prevState = { ...newNoteObj }
    prevState["contact"] = value
    setNewNoteObj(prevState)
  }

  const handleOnChangeStatus = (value) => {
    const prevState = { ...unit }
    prevState["status"] = value
    setUnit(prevState)
  }

  const setStateNewProperty = (key, value) => {
    const prevState = { ...newPropertyObj }
    prevState[key] = value
    setNewPropertyObj(prevState)
  }

  const handleLinkProperty = async (propertyID) => {
    const data = {
      propertyID: propertyID,
      unitID: unit.id
    }
    const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/properties/link`, data), {
      success: `Property has been linked!`,
      error: "Something went wrong",
      pending: `Linking property...`
    })

    fetchPropertyFns()
    console.log(response.data)
  }

  const handleUnlinkProperty = async () => {
    const data = {
      propertyID: selectedProperty.id,
      unitID: unit.id
    }
    const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/properties/link?type=unlink`, data), {
      success: `Property has been unlinked!`,
      error: "Something went wrong",
      pending: `Unlinking property...`
    })

    setSelectedProperty(null)
    fetchPropertyFns()
  }

  const handleTogglePropertyStatus = async () => {
    const data = {
      propertyID: selectedProperty.id
    }
    const response = await toast.promise(httpClient.post(process.env.REACT_APP_API_URL + `/api/properties/toggle-status`, data), {
      success: `Property status has been updated!`,
      error: "Something went wrong",
      pending: `Updating property status...`
    })

    fetchPropertyFns()
  }

  const setStateSelectedProperty = (key, value) => {
    const prevState = { ...selectedProperty }
    prevState[key] = value
    setSelectedProperty(prevState)
  }

  return (
    <div
      className={`popup__container unit-detail overlay no-scrollbar ${isActive ? "active" : ""}`}
      id='popup-unit-detail'
      onClick={closePopupWindow}
      onKeyDown={e => e.key === "Escape" && closePopupWindow()}
    >
      <div className='popup__box no-scrollbar' onClick={e => e.stopPropagation()}>

        <div className='popout_body' id='popout-body-unit'>
          <div className='popout_window__header'>
            <button className='primary-button-header' onClick={handleSaveNewObject}>SAVE</button>
            <div className='popout_window__header--title'>
              <div>{project.project_commercial_name ? project.project_commercial_name : project.project_placeholder_name}</div>
              <div>|</div>
              <div>{selectedItem?.building_id?.building_name ? selectedItem.building_id.building_name : ""}</div>
              <div>|</div>
              <div>{unit?.unit_name ? unit.unit_name : ""}</div>
            </div>
          </div>

          <div className='menu__titles'>
            <h3 className={`${menuItem == "general" ? "selected" : ""}`} onClick={() => setMenuItem("general")}>general</h3>
            <h3 className={`${menuItem == "property" ? "selected" : ""}`} onClick={() => setMenuItem("property")}>property details</h3>
            <h3 className={`${menuItem == "rental" ? "selected" : ""}`} onClick={() => setMenuItem("rental")}>rental details</h3>
            <h3 className={`${menuItem == "purchase" ? "selected" : ""}`} onClick={() => setMenuItem("purchase")}>purchase orders</h3>
          </div>

          {
            menuItem == "general" &&
            <div className={`popout_body__content_container ${actionType == 'add' ? "small" : ""}`} id="unit-general-section">
              <div className='panel'>
                <h2>Details</h2>
                <div className='form__section no-border no-padding'>

                  <div className='form__section'>
                    <div className='form__section--content'>
                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="unit_name">Status</label>
                        <PopupDropdown
                          options={["available", "sold", "rented", "inactive"]}
                          value={unit?.status}
                          onChange={handleOnChangeStatus}
                          title={"Select status"}
                          labelView={true}
                          labelColors={{
                            available: "green",
                            sold: "red",
                            rented: "blue",
                            inactive: "gray"
                          }}
                        />
                        {/* <input className="input_field__input_box--text" id="unit_status" type="text" name="unit_status" value={unit?.status} onChange={handleOnChangeInput} /> */}
                      </div>
                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="unit_name">Unit name</label>
                        <input className="input_field__input_box--text" id="unit_name" type="text" name="unit_name" value={unit?.unit_name} onChange={handleOnChangeInput} />
                      </div>
                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="unit_name">Type</label>
                        <input className="input_field__input_box--text" id="type" type="text" name="type" value={unit?.type} onChange={handleOnChangeInput} />
                      </div>

                      <div className="input_field--combo-grid">
                        <label className="input_field__label" htmlFor="unit_address">Address</label>
                        <input className="input_field__input_box--text" id="unit_address" type="text" name="address" value={unit?.address} onChange={handleOnChangeInput} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {
                actionType != 'add' &&
                <div className='panel'>
                  <h2>Notes</h2>
                  <div className='form__section'>
                    <div className='form__section--content'>

                      <PopupWindow
                        popupID={"popup-unit-notes-create"}
                        isActive={notePopupIsActive}
                        setIsActive={setNotePopupIsActive}
                      >
                        <div className='note__container--popup'>

                          <div className='form__section no-border no-padding' >
                            <div className="input_field--combo-grid">
                              <label className="input_field__label" htmlFor="note_date">Note date</label>
                              <DateField className="date-picker" format="DD-MM-YYYY" value={newNoteObj.noteDate} onChange={(value) => setStateNewNote("noteDate", value)} />
                            </div>

                            <div className="input_field--combo-grid">
                              <label className="input_field__label" htmlFor="note_type">Note type</label>
                              <PopupDropdown
                                options={Object.keys(noteTypes)}
                                value={newNoteObj.noteType}
                                onChange={handleSetStateNewNoteType}
                                title={"Select note type"}
                              />
                            </div>

                            <div className="input_field--combo-grid">
                              <label className="input_field__label" htmlFor="note_type">Contact</label>
                              <PopupDropdown
                                options={contactsList}
                                value={newNoteObj.contact}
                                onChange={handleSetStateNewNoteContact}
                                title={"Select contact"}
                                searchBar={true}
                              />
                            </div>

                            <div className="input_field--combo-grid">
                              <label className="input_field__label" htmlFor="note_subject">Subject</label>
                              <input className="input_field__input_box--text" id="note_subject" type="text" name="note_subject" value={newNoteObj.note_subject} onChange={(e) => setStateNewNote("subject", e.currentTarget.value)} />
                            </div>

                            <div className="input_field">
                              <label className="input_field__label" htmlFor="note_content">Content</label>
                              <textarea className="input_field__input_box--text-area" id="note_content" type="text" name="note_content" value={newNoteObj.note_content} onChange={(e) => setStateNewNote("content", e.currentTarget.value)} />
                            </div>

                          </div>
                        </div>
                      </PopupWindow>

                      <PopupWindow
                        popupID={"popup-unit-notes-detail"}
                        isActive={noteDetailPopupIsActive}
                        setIsActive={setNoteDetailPopupIsActive}
                      >
                        {
                          selectedNote != null &&
                          <div className='note__container--popup'>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Date:</div>
                              <p>{formatDate(selectedNote.date_note)}</p>
                            </div>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Created by:</div>
                              <p>{selectedNote.note_maker_user_id?.first_name}</p>
                            </div>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Contact:</div>
                              {
                                selectedNote.contact_id ?
                                  <p>{selectedNote.contact_id.first_name} {selectedNote.contact_id.last_name}</p> :
                                  selectedNote.company_id ?
                                    <p>{selectedNote.company_id.company_name} (company)</p> : null
                              }
                            </div>
                            <div className='date_note--popup'>
                              <div className='popup_window__subtitle'>Subject:</div>
                              <p>{selectedNote.subject}</p>
                            </div>
                            <div className='note_text--popup'>
                              <div className='popup_window__subtitle'>Note:</div>
                              <p>{selectedNote.note}</p>
                            </div>
                          </div>
                        }
                      </PopupWindow>

                      <button className='more--btn no-margin' onClick={handleCreateNote}>+</button>
                      {
                        notes.map((note, index) => {
                          return (
                            <Note
                              popupWindowID="popup-contact-notes"
                              key={index}
                              noteObj={note}
                              type={note.type}
                              note={note.note}
                              subject={note.subject}
                              date={note.date_note}
                              setSelectedNote={setSelectedNote}
                              setPopupActive={setNoteDetailPopupIsActive}
                            />
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
              }
            </div>
          }

          {
            menuItem == "property" &&
            <div className='popout_body__content_container' id="unit-property-section">
              <div className="panel">
                <h2>Properties</h2>
                {
                  loading.properties &&
                  <div className='loading__container'>
                    <CircularProgress color="inherit" />
                  </div>
                }
                {
                  !loading.properties &&
                  <div className='form__section'>
                    <div className='form__section--content'>
                      <button className='more--btn no-margin' onClick={() => {setLinkPropertyIsActive(!linkPropertyIsActive);setSelectedProperty(null)}}>+</button>
                      <div className='properties_list'>
                      {
                        propertiesOfUnit
                        .sort((a, b) => (b.is_active === true) - (a.is_active === true))
                        .map((property, index) => {
                          return (
                            <div 
                              className={`property-item list ${property.is_active ? "" : "inactive"}`} 
                              key={index}
                              onClick={() => {setLinkPropertyIsActive(false);setSelectedProperty(property)}}
                            > 
                              <div>{property.cadastral_nr}</div>
                              <div>{property.location}</div>
                              <div>{property.function}</div>
                            </div>
                          )
                        })
                      }
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className='panel'>
                {
                  linkPropertyIsActive && !selectedProperty &&
                  <div className='form__section'>
                    <PopupWindow
                      popupID={"popup-unit-property-create"}
                      isActive={createPropertyPopupIsActive}
                      setIsActive={setCreatePropertyPopupIsActive}
                    >
                      <div className='date_note--popup'>
                        <div className="input_field--combo-grid">
                          <label className="input_field__label" htmlFor="cadastral_nr">Cadastral ID</label>
                          <input className="input_field__input_box--text" id="cadastral_nr" type="text" name="cadastral_nr" value={newPropertyObj.cadastral_nr} onChange={(e) => setStateNewProperty("cadastral_nr", e.currentTarget.value)} />
                        </div>
                        <div className="input_field--combo-grid">
                          <label className="input_field__label" htmlFor="location">Location</label>
                          <input className="input_field__input_box--text" id="location" type="text" name="location" value={newPropertyObj.location} onChange={(e) => setStateNewProperty("location", e.currentTarget.value)} />
                        </div>
                        <div className="input_field--combo-grid">
                          <label className="input_field__label" htmlFor="function">Function</label>
                          <input className="input_field__input_box--text" id="function" type="text" name="function" value={newPropertyObj.function} onChange={(e) => setStateNewProperty("function", e.currentTarget.value)} />
                        </div>
                        <div className="input_field--combo-grid">
                          <label className="input_field__label" htmlFor="surface">Surface (m²)</label>
                          <input className="input_field__input_box--text" id="surface" type="number" name="surface" value={newPropertyObj.surface} onChange={(e) => setStateNewProperty("surface", e.currentTarget.value)} />
                        </div>
                        <div className="input_field--combo-grid">
                          <label className="input_field__label" htmlFor="GFAa_underground">GFAa underground (m²)</label>
                          <input className="input_field__input_box--text" id="GFAa_underground" type="number" name="GFAa_underground" value={newPropertyObj.GFAa_underground} onChange={(e) => setStateNewProperty("GFAa_underground", e.currentTarget.value)} />
                        </div>
                        <div className="input_field--combo-grid">
                          <label className="input_field__label" htmlFor="GFAa">GFAa (m²)</label>
                          <input className="input_field__input_box--text" id="GFAa" type="number" name="GFAa" value={newPropertyObj.GFAa} onChange={(e) => setStateNewProperty("GFAa", e.currentTarget.value)} />
                        </div>
                        <div className="input_field--combo-grid">
                          <label className="input_field__label" htmlFor="GFAb">GFAb (m²)</label>
                          <input className="input_field__input_box--text" id="GFAb" type="number" name="GFAb" value={newPropertyObj.GFAb} onChange={(e) => setStateNewProperty("GFAb", e.currentTarget.value)} />
                        </div>
                        <div className="input_field--combo-grid">
                          <label className="input_field__label" htmlFor="GFAc">GFAc (m²)</label>
                          <input className="input_field__input_box--text" id="GFAc" type="number" name="GFAc" value={newPropertyObj.GFAc} onChange={(e) => setStateNewProperty("GFAc", e.currentTarget.value)} />
                        </div>
                        <div className="input_field--combo-grid">
                          <label className="input_field__label" htmlFor="EPC_label">EPC label</label>
                          <input className="input_field__input_box--text" id="EPC_label" type="text" name="EPC_label" value={newPropertyObj.EPC_label} onChange={(e) => setStateNewProperty("EPC_label", e.currentTarget.value)} />
                        </div>
                        <div className="input_field">
                          <label className="input_field__label" htmlFor="description">Description</label>
                          <textarea className="input_field__input_box--text-area" id="description" type="text" name="description" value={newPropertyObj.description} onChange={(e) => setStateNewProperty("description", e.currentTarget.value)} />
                        </div>
                      </div>
                    </PopupWindow>

                    <h2>Connect property</h2>
                    <input
                      className='search-bar--list'
                      type="text"
                      name="property_name"
                      value={propertySearchValue}
                      onChange={(e) => setPropertySearchValue(e.currentTarget.value)}
                      placeholder="Search property"
                    />
                    <div className='properties_list'>
                      <button className='list-button' onClick={() => setCreatePropertyPopupIsActive(true)}> <CiSquarePlus /> Create new property</button>
                      {
                        properties
                          .filter(property => !propertiesOfUnit.some(unitProperty => unitProperty.cadastral_nr === property.cadastral_nr))
                          .filter(property => (
                            property.cadastral_nr.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                            property.location.toLowerCase().includes(propertySearchValue.toLowerCase()) ||
                            property.function.toLowerCase().includes(propertySearchValue.toLowerCase())
                          ))
                          .map((property, index) => {
                            return (
                              <div className='property-item' key={index} onClick={() => handleLinkProperty(property.id)}>
                                <div>{property.cadastral_nr}</div>
                                <div>{property.location}</div>
                                <div>{property.function}</div>
                              </div>
                            )
                          })
                      }
                    </div>
                  </div>
                }

                {
                  selectedProperty &&
                  <div className='form__section'>
                    <h2>Property details</h2>
                    <div className='action-buttons'>
                      <button className='icon-button' onClick={() => setEditProperty(!editProperty)}><FaRegEdit /></button>
                      <button className='icon-button' onClick={handleTogglePropertyStatus}><HiMiniPower /></button>
                      <button className='icon-button red' onClick={handleUnlinkProperty}><GoUnlink /></button>
                    </div>
                    <div className='property_details--container'>
                      <div className='property_details--content'>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="cadastral_nr">Cadastral ID</label>
                          <input 
                            className="input_field__input_box--text" 
                            id="cadastral_nr" 
                            type="text" 
                            name="cadastral_nr" 
                            value={selectedProperty.cadastral_nr} 
                            onChange={(e) => setStateSelectedProperty("cadastral_nr", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="location">Location</label>
                          <input 
                            className="input_field__input_box--text" 
                            id="location" 
                            type="text" 
                            name="location" 
                            value={selectedProperty.location} 
                            onChange={(e) => setStateSelectedProperty("location", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="function">Function</label>
                          <input 
                            className="input_field__input_box--text" 
                            id="function" 
                            type="text" 
                            name="function" 
                            value={selectedProperty.function} 
                            onChange={(e) => setStateSelectedProperty("function", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="surface">Surface (m²)</label>
                          <input 
                            className="input_field__input_box--text" 
                            id="surface" 
                            type="number" 
                            name="surface" 
                            value={selectedProperty.surface} 
                            onChange={(e) => setStateSelectedProperty("surface", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="GFAa_underground">GFAa underground (m²)</label>
                          <input 
                            className="input_field__input_box--text" 
                            id="GFAa_underground" 
                            type="number" 
                            name="GFAa_underground" 
                            value={selectedProperty.GFAa_underground} 
                            onChange={(e) => setStateSelectedProperty("GFAa_underground", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="GFAa">GFAa (m²)</label>
                          <input 
                            className="input_field__input_box--text" 
                            id="GFAa" 
                            type="number" 
                            name="GFAa" 
                            value={selectedProperty.GFAa} 
                            onChange={(e) => setStateSelectedProperty("GFAa", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="GFAb">GFAb (m²)</label>
                          <input 
                            className="input_field__input_box--text" 
                            id="GFAb" 
                            type="number" 
                            name="GFAb" 
                            value={selectedProperty.GFAb} 
                            onChange={(e) => setStateSelectedProperty("GFAb", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="GFAc">GFAc (m²)</label>
                          <input 
                            className="input_field__input_box--text" 
                            id="GFAc" 
                            type="number" 
                            name="GFAc" 
                            value={selectedProperty.GFAc} 
                            onChange={(e) => setStateSelectedProperty("GFAc", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="EPC_label">EPC label</label>
                          <input 
                            className="input_field__input_box--text" 
                            id="EPC_label" 
                            type="text" 
                            name="EPC_label" 
                            value={selectedProperty.EPC_label} 
                            onChange={(e) => setStateSelectedProperty("EPC_label", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                        <div className={`input_field--simple ${editProperty ? "edit" : ""}`}>
                          <label className="input_field__label" htmlFor="description">Description</label>
                          <textarea 
                            className="input_field__input_box--text-area" 
                            id="description" 
                            type="text" 
                            name="description" 
                            value={selectedProperty.description} 
                            onChange={(e) => setStateSelectedProperty("description", e.currentTarget.value)} 
                            disabled={!editProperty} 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          }

          {
            menuItem == "rental" &&
            <>
            </>
          }

          {
            menuItem == "purchase" &&
            <>
            </>
          }
        </div>

      </div>
    </div>
  )
}

export default PopupWindowUnitDetail