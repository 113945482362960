import React, { useState, useEffect } from 'react'
import './Note.css'
import { HiOutlineMailOpen } from "react-icons/hi"
import { formatDate } from './helpers/formatDate'
import { noteTypes } from '../assets/data/data'

function Note({ type, note, date, subject, setSelectedNote, noteObj, popupWindowID, setPopupActive }) {
  const [picto, setPicto] = useState(null)

  const prospectPicto = require(`../assets/pictos/researcher.png`)
  const emailPicto = require(`../assets/pictos/email.png`)
  const formPicto = require(`../assets/pictos/form.png`)
  const phonePicto = require(`../assets/pictos/smartphone.png`)
  const visitPicto = require(`../assets/pictos/building.png`)
  const meetingPicto = require(`../assets/pictos/meeting.png`)
  const notePicto = require(`../assets/pictos/note.png`)

  useEffect(() => {
    const defaultPicto = noteTypes['Note'].picto // Default to 'Note' picto if the type is not found
    setPicto(noteTypes[type]?.picto || defaultPicto)
  }, [type])

  const handleOnClickNote = () => {
    setPopupActive(true)
    setSelectedNote(noteObj)
  }

  return (
    <div className='note__container' onClick={handleOnClickNote}>
      <div className='note_icon'><img src={picto} /></div>
      <div className='date_note'>{formatDate(date)}</div>
      <div className='note_text'>{subject}</div>
    </div>
  )
}

export default Note