import React, { useState, useEffect } from 'react'
import "./CheckBox.css"

function CheckBox({ width, height, textPosition, state, text, setData, setState, margin, padding, marginLeft }) {
  const [isChecked, setIsChecked] = useState(state)

  CheckBox.defaultProps = {
    "height": "30px",
    "width": "100%",
    "textPosition": "left",
    "margin": "5px 0px",
    "padding": "0px"
  }

  useEffect(() => {
    setIsChecked(state)
  }, [state])

  const handleOnClickCheckbox = () => {
    setState(!state)
    try {
      setData()
    }
    catch (error) {
      console.log("No data to set")
    }
  }

  return (
    <div className="checkbox__container" onClick={handleOnClickCheckbox} style={{width, margin, padding}}>
      {
        textPosition === "left" & text != undefined ? <p style={{ marginRight: "auto" }} className="checkbox--text">{text}</p> : null
      }
      <div className="checkbox--box__container">
        <div className={isChecked ? "checkbox--box checked" : "checkbox--box"} style={{ "width": height, "height": height }}>
          <span className={isChecked ? "checkmark checked" : "checkmark"} >
            <div className="checkmark_stem"></div>
            <div className="checkmark_kick"></div>
          </span>
        </div>
      </div>
      {
        textPosition === "right" & text != undefined ? <p style={{ marginLeft: "auto" }} className="checkbox--text">{text}</p> : null
      }
    </div>
  )
}

export default CheckBox

