import React, { useState, useEffect, useRef } from 'react'
import './ProjectsPage.css'
import { useSelector, useDispatch } from "react-redux"
import MenuBar from "../components/MenuBar"
import Header from "../components/Header"
import { useNavigate, useParams } from "react-router-dom"
import LoadingSpinner from '../components/placeholders/LoadingSpinner'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import httpClient from '../components/helpers/httpClient'
import Label from '../components/Label'
import DropDownFilter from '../components/DropDownFilter'
import { projectStatuses } from '../assets/data/data'
import { updateFilters } from '../store/projectsPage'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import Dropdown from '../components/Dropdown'
import CircularProgress from '@mui/material/CircularProgress'

function ProjectsPage() {
  const [projects, setProjects] = useState([])
  const [loadingProjects, setLoadingProjects] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [limit, setLimit] = useState(25)
  const [createNew, setCreateNew] = useState(false)
  const [newProjectStatus, setNewProjectStatus] = useState("")
  const [statuses, setStatuses] = useState([])
  const [tagsList, setTagsList] = useState([])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const saveButton = useRef(null)
  const filters = useSelector(state => state.projectsPage.filters)

  const fetchTagsList = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + "/api/tags/get")
    const data = response.data
    const transformedData = data.map(tag => {
      return (
        {
          "id": tag.id,
          "name": tag.tag
        }
      )
    })

    setTagsList(transformedData)
  }

  const fetchProjectStatuses = async () => {
    const response = await httpClient.get(process.env.REACT_APP_API_URL + `/api/projects/statuses`)
    const data = response.data
    const transformedData = data.map(status => {
      return (
        { "id": status.id, "name": status.status_name }
      )
    })
    setStatuses(transformedData)
  }

  const fetchProjects = async () => {
    setLoadingProjects(true)
    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/projects/get?page=${filters.page}&limit=${limit}`, filters)
    setProjects(response.data.projects)
    setTotalPages(response.data.total_pages)
    setLoadingProjects(false)
  }

  useEffect(() => {
    fetchProjects()
    fetchProjectStatuses()
    fetchTagsList()
  }, [])

  useEffect(() => {
    fetchProjects()
  }, [filters])

  const handleOnClickRow = (projectID) => {
    navigate(`/projects/${projectID}`)
  }

  const handlePagination = (event) => {
    const element = event.currentTarget

    if (element.classList.contains("grayed-out")) {
      return
    }

    const type = element.dataset.type

    const updatedFilters = { ...filters }

    switch (type) {

      case "previous":
        updatedFilters["page"] = filters.page - 1

        dispatch(updateFilters(updatedFilters))
        break
      case "next":
        updatedFilters["page"] = filters.page + 1

        dispatch(updateFilters(updatedFilters))
        break
    }
  }

  const handleOnClickDropdownItem = (e, id = null, idList) => {
    let value = e.currentTarget.textContent

    const newFiltersObj = { ...filters }

    switch (idList) {
      case 'projects_filter--status':
        if (id) {
          const status = statuses.find(status => status.id == id)
          value = status
        }
        newFiltersObj.statusFilter = value
        break
      case 'projects_filter--tag':
        if (id) {
          const status = tagsList.find(status => status.id == id)
          value = status
        }
        newFiltersObj.tagFilter = value
        break
    }

    newFiltersObj.page = 1
    dispatch(updateFilters(newFiltersObj))
  }

  const handleSearchWord = (event) => {
    const updatedFilters = { ...filters }
    updatedFilters["searchWord"] = event.target.value
    updatedFilters["page"] = 1

    dispatch(updateFilters(updatedFilters))
  }

  const handleClearSearchBar = () => {
    if (filters.searchWord == "") {
      return
    } else {
      const updatedFilters = { ...filters }
      updatedFilters["searchWord"] = ""
      updatedFilters["page"] = 1

      dispatch(updateFilters(updatedFilters))
    }
  }

  const handleSetSortFilter = (event) => {

    const innerHTML = event.currentTarget.innerHTML
    const updatedFilters = { ...filters }

    switch (innerHTML) {
      case "id":
        if (filters.sortFilter == "id") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "id"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Working name":
        if (filters.sortFilter == "project_placeholder_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "project_placeholder_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Commercial name":
        if (filters.sortFilter == "project_commercial_name") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "project_commercial_name"
          updatedFilters["sortAscending"] = true
        }
        break
      case "Status":
        if (filters.sortFilter == "status") {
          updatedFilters["sortAscending"] = !filters.sortAscending
        } else {
          updatedFilters["sortFilter"] = "status"
          updatedFilters["sortAscending"] = true
        }
        break

    }
    updatedFilters["page"] = 1
    dispatch(updateFilters(updatedFilters))
  }

  const handleSaveNewProject = async () => {
    saveButton.disabled = true

    const newProjectInfo = {}
    const inputElements = document.querySelectorAll(".input_field__input_box--text")

    inputElements.forEach(element => {
      const key = element.getAttribute('name')
      newProjectInfo[key] = element.value
    })

    const data = {
      newProjectStatus,
      newProjectInfo
    }

    const response = await httpClient.post(process.env.REACT_APP_API_URL + `/api/projects/create`, data)
    console.log(response.data)

    fetchProjects()

    // cleanup
    setNewProjectStatus("")
    setCreateNew(false)

    inputElements.forEach(element => {
      element.value = ""
    })

    saveButton.disabled = false
  }

  return (
    <div className='template'>

      <Header>
        {
          createNew ?
            <button className="primary-button-header--right" id="projects__btn-save" ref={saveButton} onClick={handleSaveNewProject}>SAVE</button>
            :
            <div className="header__filters" id="products__table-btns">
              <div className="search-bar" id="products__search-bar--table" onClick={handleClearSearchBar}>
                <input placeholder="Search project..." value={filters.searchWord} onChange={handleSearchWord} />
                {filters.searchWord == "" ? <SearchIcon /> : <ClearIcon />}
              </div>

              <DropDownFilter
                idList="projects_filter--status"
                placeholderText="Filter on status"
                listArray={statuses}
                onClickItem={handleOnClickDropdownItem}
                filters={filters}
                value={filters.statusFilter.name}
                filter={"statusFilter"}
                setValue={updateFilters}
              />

              <DropDownFilter
                idList="projects_filter--tag"
                placeholderText="Filter on tag"
                listArray={tagsList}
                onClickItem={handleOnClickDropdownItem}
                filters={filters}
                value={filters.tagFilter.name}
                filter={"tagFilter"}
                setValue={updateFilters}
              />
            </div>
        }
      </Header>

      <MenuBar />

      <div className="template__body" id='body-projects'>
        <button className="floating__button--bottom-right" id="add--btn" onClick={() => setCreateNew(!createNew)} />

        <div className={`body__section ${createNew ? "" : "hide"}`} id='new-project-form'>
          <div className="form__section">
            <div className="input_field">
              <label className="input_field__label" htmlFor="project_placeholder_name">Working name</label>
              <input className="input_field__input_box--text" id="project_placeholder_name" type="text" name="project_placeholder_name" />
            </div>
            <div className="input_field">
              <label className="input_field__label" htmlFor="project_commercial_name">Commercial name</label>
              <input className="input_field__input_box--text" id="project_commercial_name" type="text" name="project_commercial_name" />
            </div>
            <div className="input_field">
              <label className="input_field__label" htmlFor="code_aangifte_van_werken">Code aangifte van werken</label>
              <input className="input_field__input_box--text" id="code_aangifte_van_werken" type="text" name="code_aangifte_van_werken" />
            </div>
          </div>
          <div className="form__section">
            <div className='flex--vert dropdown'>
              <label className="input_field__label" htmlFor="company_number">Project status</label>
              <Dropdown
                index={"project-status"}
                listArray={statuses}
                state={newProjectStatus}
                setState={setNewProjectStatus}
                placeholder={""}
                width={"100%"}
                searchBar={false}
                margin={"0"}
                objDropdownType={"normal"}
              />
            </div>
          </div>
        </div>
        <div className={`body__section ${createNew ? "" : "big"}`} id='all-projects-table'>
          <div className='table'>
            <div className='table__header-4'>
              <div className='table__header--field' onClick={handleSetSortFilter}>id</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Working name</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Commercial name</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Status</div>
              <div className='table__header--field' onClick={handleSetSortFilter}>Tags</div>
            </div>
            <div className='table__rows_container'>

              {
                loadingProjects ?
                  <LoadingSpinner /> :

                  projects.map((project, index) => {
                    return (
                      <div className='table__row-4' key={index} onClick={() => handleOnClickRow(project.id)} data-project-id={project.id}>
                        <div className='table__row--field'>{project.id}</div>
                        <div className='table__row--field'>{project.project_placeholder_name ? project.project_placeholder_name : "N/A"}</div>
                        <div className='table__row--field'>{project.project_commercial_name ? project.project_commercial_name : "TBD"}</div>
                        <div className='table__row--field'>{project.status_id ? <Label title={project.status_id.status_name} labelColor={project.status_id.label_color} /> : null}</div>
                        <div className='table__row--field tags'>
                          {
                            project.tags.map(tag => (
                              <div className='tag' key={tag.id}>{tag.tag_id.tag}</div>
                            ))
                          }
                        </div>
                      </div>
                    )
                  })
              }
            </div>
            <div className="pagination">
              <ArrowBackIosIcon className={`pagination__btn ${filters.page == 1 ? "grayed-out" : ""}`} onClick={handlePagination} data-type="previous" id="prevPage" />
              <ArrowForwardIosIcon className={`pagination__btn ${filters.page == totalPages ? "grayed-out" : ""}`} onClick={handlePagination} data-type="next" id="nextPage" />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ProjectsPage