import React from "react"

function Sold() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M38.1 1H1v46h2V3h12.5v5.8H8.6v26.5H47V8.8h-6.9V3H47V1h-6.9zm6.9 9.8v22.5H10.6V10.8h29.5zm-6.9-2H17.5V3h20.6z"></path>
      <path d="M19.5 21.7c-.3-.2-.8-.3-1.5-.5s-1.1-.3-1.3-.5c-.1-.1-.2-.3-.2-.4 0-.2.1-.3.2-.4.2-.2.5-.2.9-.2s.7.1.9.2.3.4.4.8l1.4-.1c0-.6-.2-1.1-.7-1.5-.4-.4-1.1-.6-1.9-.6-.5 0-1 .1-1.3.2-.4.2-.7.4-.8.7s-.3.6-.3 1c0 .5.2 1 .6 1.4.3.3.8.5 1.5.7.6.1.9.2 1.1.3s.4.2.5.3.1.2.1.4-.1.5-.3.7-.6.3-1 .3-.7-.1-1-.3c-.2-.2-.4-.5-.5-1l-1.3.1c.1.8.4 1.3.8 1.7.5.4 1.1.6 2 .6.6 0 1.1-.1 1.5-.2.4-.2.7-.4.9-.8s.3-.7.3-1.1-.1-.8-.3-1.1c-.1-.3-.3-.6-.7-.7zM24.7 18.5c-.6 0-1 .1-1.5.3-.3.1-.6.3-.9.6s-.5.6-.6.9c-.2.5-.3 1-.3 1.7 0 1.1.3 1.9.9 2.5s1.4.9 2.4.9 1.8-.3 2.4-.9c.6-.5.9-1.4.9-2.5s-.3-2-.9-2.6-1.4-.9-2.4-.9zm1.4 5.3c-.3.4-.8.6-1.3.6s-1-.2-1.4-.6-.5-1-.5-1.7c0-.8.2-1.4.5-1.8s.8-.6 1.4-.6 1 .2 1.4.6c.3.4.5 1 .5 1.7-.1.8-.3 1.4-.6 1.8zM30.5 18.7h-1.4v6.7h4.8v-1.1h-3.4zM34.9 18.6v6.8h2.6c.5 0 .9 0 1.2-.1.4-.1.7-.3 1-.5.3-.3.6-.7.7-1.2.1-.4.2-.9.2-1.4 0-.6-.1-1.2-.2-1.6s-.4-.8-.6-1.1c-.3-.3-.6-.5-1-.6-.3-.1-.7-.1-1.3-.1h-2.6zm3.7 1.6c.2.2.3.4.4.7s.1.7.1 1.2 0 .9-.1 1.2-.2.5-.4.7c-.1.1-.3.2-.6.3-.2 0-.4.1-.8.1h-1v-4.5h.6c.6 0 .9 0 1.1.1.3-.1.5 0 .7.2z"></path>
    </svg>
  );
}

export default Sold