import React, { useState, useEffect, useRef } from 'react'
import './Dropdown.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

function Dropdown({
  state, margin, fontSize, setState, height, width, idList, index, placeholder, listArray,
  searchBar, parentDivRef, maxHeightUL, placeholderSkeleton, disabled, showID, objDropdownType,
  stateType, stateKey
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState("")
  const [searchValue, setSearchValue] = useState("")

  const ref = useRef(null)
  const dropdownListRef = useRef(null)

  Dropdown.defaultProps = {
    searchBar: false,
    height: "30px",
    margin: "5px 0",
    maxHeightUL: "auto",
    placeholderSkeleton: false,
    showID: false,
    objDropdownType: "concat",
  }

  useEffect(() => {
    if (state == undefined) {
      setSelectedValue("")
      return
    }

    if (typeof state === 'object') {

      let value = ""

      if (objDropdownType == 'concat') {
        Object.values(state).forEach(v => {
          value += ` ${v}`
        })
      }
      if (objDropdownType == 'normal') {
        value = state.name
      }

      setSelectedValue(value)
    } else {
      setSelectedValue(state)
    }
  }, [state])

  const onClickOutside = () => {
    const dropdownList = document.querySelectorAll(`#${idList}`)

    Array.from(dropdownList).forEach(dropdown => {
      dropdown.classList.remove("active")
    })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside()
      }
    }
    document.addEventListener("click", handleClickOutside, true)

    setIsOpen(false)

    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [onClickOutside])

  useEffect(() => {
    let dropdownElement = null;
    let parentDivElement = null;

    if (isOpen) {
      dropdownElement = dropdownListRef.current

      if (parentDivRef == undefined) {
        parentDivElement = dropdownElement.parentNode.parentNode
      } else {
        parentDivElement = parentDivRef.current
      }
    }

    if (dropdownElement) {
      const dropdownRect = dropdownElement.getBoundingClientRect()
      const parentDivRect = parentDivElement.getBoundingClientRect()

      const { top: dropdownTop, height: dropdownHeight } = dropdownRect
      const { top: parentDivTop, height: parentDivHeight } = parentDivRect

      const dropdownBottom = dropdownTop + dropdownHeight
      const parentDivBottom = parentDivTop + parentDivHeight

      if (dropdownBottom > parentDivBottom) {
        // Calculate the amount to scroll by
        const scrollAmount = dropdownBottom - parentDivBottom + 20
        // Scroll the parent div with smooth animation
        parentDivElement.scrollBy({
          top: scrollAmount,
          behavior: 'smooth',
        })
      }
    }
  }, [isOpen])

  const handleClickDropdown = (event) => {
    setIsOpen(true)
    const dropdownList = event.currentTarget.nextSibling
    dropdownList.classList.toggle("active")

    if (searchBar) {
      const searchBar = document.querySelector(`#search-bar--${idList}`)
      searchBar.focus()
    }
  }

  const onclickMenuItem = (event) => {
    const dropdownList = event.currentTarget.parentNode

    let value = ''

    if (typeof listArray[0] === 'object') {
      const id = event.currentTarget.getAttribute('data-id')
      const itemValues = event.currentTarget.childNodes

      if (objDropdownType == 'concat') {
        itemValues.forEach(v => {
          value += ` ${v.textContent}`
        })
      }
      if (objDropdownType == 'normal') {
        value = state.name
      }

      setSelectedValue(value)

      // Find the object in the listArray with the matching id
      const itemWithId = listArray.find(item => item.id == id)
      if (itemWithId) {
        value = itemWithId
      }
    }

    if (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') {
      setSelectedValue(event.currentTarget.textContent)
      value = event.currentTarget.textContent
    }

    if (stateType == "object") {
      setState(stateKey, value)
    } else {
      setState(value)
    }

    dropdownList.classList.remove("active")
    setIsOpen(false)
  }

  if ((!listArray || listArray.length === 0) && placeholderSkeleton) {
    return (
      <div className="skeleton-loader" style={{ width, height, margin }}>
        {/* You can add additional elements inside if you want to mimic internal structure */}
      </div>
    )
  }

  return (
    <div className={`dropdown__container-dropdown classic flex--vert ${disabled ? "disabled" : ""}`} id={`dropdown__${index}`} style={{ width, margin }} ref={ref} key={index}>
      <span className="input_field__input_box--text--dropdown dropdown classic flex" style={{ height }} onClick={handleClickDropdown}>
        <p className={`dropdown-input--text_field ${selectedValue == undefined | selectedValue == "" ? "placeholder" : selectedValue}`} style={{ fontSize }}>{selectedValue == undefined | selectedValue == "" ? placeholder : selectedValue}</p>
        <KeyboardArrowDownIcon />
      </span>
      <ul className="input_field__dropdown classic" style={{ "maxHeight": maxHeightUL }} id={idList} key={index} ref={dropdownListRef}>
        {
          searchBar &&
          <input className="search-bar dropdown" id={`search-bar--${idList}`} placeholder="Search..." value={searchValue} onChange={event => setSearchValue(event.currentTarget.value)} autoComplete="off" />
        }
        {
          typeof listArray[0] === 'object' && listArray[0] !== null && (
            listArray
              .filter(item => {
                // Combine all object values into a single string and lower case it for comparison
                const combinedValuesString = Object.values(item).map(value => String(value).toLocaleLowerCase()).join(" ");
                // Split the searchValue into individual words and lower case them
                const searchWords = searchValue.toLocaleLowerCase().split(" ");
                // Check if all search words are included in the combinedValuesString
                return searchWords.every(word => combinedValuesString.includes(word));
              })
              .map((item, index) => (
                <li className='dropdown__item' key={index} data-index={index} data-id={item.id} style={{ fontSize }} onClick={onclickMenuItem}>
                  {
                    Object.entries(item).map((entry, index) => {
                      if (entry[0] != "id" | showID) {
                        return (
                          <div key={index}>
                            {entry[1]}
                          </div>
                        )
                      }
                    })
                  }
                </li>
              ))
          )
        }

        {
          (typeof listArray[0] === 'string' || typeof listArray[0] === 'number') && (
            listArray.map((item, index) => {
              return (
                <li className='dropdown__item--single' key={index} style={{ fontSize }} onClick={onclickMenuItem}>
                  {item}
                </li>
              )
            })
          )
        }

      </ul>
    </div>
  )
}

export default Dropdown