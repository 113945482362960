import React, {useState, useEffect} from "react"
import httpClient from "./helpers/httpClient"
import "./Header.css"
import { useSelector, useDispatch } from "react-redux"

function Header(props) {
  
  const userInfo = useSelector(state => state.user)
  let message 

  switch (userInfo.type) {
    case "admin":
      message = "I hope you are having an awesome day"
      break
    default:
      message = "🤖"
  }

  return (
    <div className="template__header" id="header">
      <div id="header-title" className="hide">
        <h1>Hi {userInfo.first_name}! 🥷</h1>
        <p className="header--p">{message}</p>
      </div>
      {props.children}
    </div>
)}

export default Header
